import React from "react";
import { message, message as notify, Spin } from "antd";
import { useTranslate, usePermission, useFetch } from "../../hooks";
import { useHistory, useParams } from "react-router-dom";
import { notifMessageApi } from "../../apis";
import DeliveryMessageForm from "./components/DeliveryMessageForm";
import { Button } from "../../components/Design";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";

export default () => {
  const { type } = useParams();
  const { translate } = useTranslate();
  const { checkRole } = usePermission();
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState();
  const [editable, fetching] = useFetch(notifMessageApi.get, type)(null);
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const history = useHistory();

  // const reload = React.useCallback(async (signal) => {
  //   setLoading(true);
  //   try {
  //     let res = await notifMessageApi.get(type, { signal });
  //     setEditable(["update", res]);
  //   } catch (err) {
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [type]);

  // React.useEffect(() => {
  //   const abortController = new AbortController();
  //   const signal = abortController.signal;

  //   reload(signal);

  //   return () => abortController.abort();
  // }, [reload]);

  // if (!editable || loading)
  //   return <Spin spinning={true}/>;


  const onSave = async () => {
    let data = await formRef.current.validate();

    if (!data) return;

    setLoading(true);

    try {
      await notifMessageApi.update({
        ...data,
        type
      });
    } catch (error) {
      message.error(error.message);
    }

    setLoading(false);
    notify.success(translate("system.message.success", "Success"));
    history.push("/deliverymessage");
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate(`system.constant.deliverymessage.type.${type}`, type)} extra={[
        disabled && (<Button key={3} type="primary" loading={loading} onClick={() => onSave()}>{translate("system.save", "Save")}</Button>)
      ]} />
      <PageContent>
        {fetching &&
          <Spin>
            <div></div>
          </Spin>}
        {!fetching && (() => {
          return <DeliveryMessageForm ref={formRef} deliveryMessage={editable} />;
        })()}
      </PageContent>
    </PageContainer>
  );
};