import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm } from "antd";
import * as Yup from "yup";
import colors from "../../../colors";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";

const { Option } = Select;


const FormSchema =(translate) => Yup.object({
  type   : Yup.string().required(translate("system.message.required", "This field is required!")),
  orderNo: Yup.string().max(50, translate("tag.name.limit", "Tag name exceeded character limit")),
  phone  : Yup.string().max(50, translate("tag.name.limit", "Tag name exceeded character limit")),
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data, setFormData] = React.useState({
    phone  : "",
    orderNo: "",
    type   : action[1]?.type
  });

  const onChangeType = (value, values) => {
    setFormData({
      sendType: value,
      ...values,
      phone   : "",
      orderNo : "",
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("delivery.message.send.type", "Type")} name="sendType" required>
              <Select disabled={action[0] === "update"} name="sendType" placeholder="Select" onChange={(value) => onChangeType(value, values)} style={{ width: "100%" }}>
                {[{ code: "phone", name: "電話番号" }, { code: "order", name: "注文番号" }, { code: "both", name: "両方" }].map((type, index) => {
                  return <Option key={index} value={type.code}>{type.name}</Option>;
                })}
              </Select>
            </FormItem>
            {
              (values.sendType === "order" || values.sendType === "both") && <FormItem label={translate("delivery.message.send.order", "Order No:")} name="orderNo" required>
                <Input name="orderNo" placeholder={translate("delivery.message.send.order", "Order No:")} />
              </FormItem>
            }
            {
              (values.sendType === "phone" || values.sendType === "both") && <FormItem label={translate("delivery.message.send.phone", "Phone")} name="phone" required>
                <Input name="phone" placeholder={translate("delivery.message.send.phone", "Phone")} />
              </FormItem>
            }
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

