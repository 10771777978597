import React from "react";
import { Field } from "formik";
import { Modal } from "antd";
import { Button } from "../Design";
import { CheckOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslate, usePermission } from "../../hooks";

const COLORS = [
  "#ffffff", "#7f021a", "#c00001", "#c9162b", "#fa4a4d", "#ec5f47", "#ffa42d", "#f7c11d", "#20c46d", "#389d4a", "#07a683", "#45c4b7",
  "#2cbdda", "#178acd", "#3872bf", "#5671d9", "#015ea6", "#ac82e6", "#8f64cf", "#ab53e0", "#e85bbb", "#fe9ccd",
  "#ff8da6", "#ca9560", "#867059", "#584539", "#332a25", "#789d54", "#56714c", "#01713f", "#035e5c", "#4e6a78",
  "#4a5d69", "#35445e", "#7e6d85", "#876e77", "#8d5b6a", "#673b4c", "#573962", "#979797", "#505050", "#262626",
];

export default ({ label, name }) => {
  const { translate, changeLanguage, lang } = useTranslate();
  const [action, setAction] = React.useState(["color"]);

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;

        const onEdit = () => {
          setAction(["schema", action[1]]);
        };

        const onCancel = () => {
          setAction(["color"]);
        };

        const onConfirm = () => {
          setFieldValue(name, action[1]);
          setAction(["color"]);
        };

        const onColor = (item) => {
          setAction(["schema", item]);
        };

        return (
          <ColorSchema>
            <Color className={`small ${value === "#ffffff" && "white"}`} onClick={onEdit}
              style={{ margin: "0 10px 0 0", backgroundColor: value }}>
            </Color>
            <Button type="default" onClick={onEdit}>{translate("system.edit", "Edit")}</Button>

            <Modal
              maskClosable={false}
              visible={action && action[0] === "schema"}
              className="schema-modal"
              title={label || translate("privatechat.note.colors", "Color")}
              onCancel={() => setAction(["color", action[1]])}
              footer={
                <>
                  <Button type="default" onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
                  <Button style={{ marginRight: "10px" }} type="primary" onClick={onConfirm}>{translate("system.confirm", "Confirm")}</Button>
                </>
              }>
              <ColorList>
                {COLORS.map((item, index) => {
                  return (
                    <Color key={index} className={`${(action[1] || value) === item && "active"} ${item === "#ffffff" && "white"}`} onClick={() => onColor(item)} style={{
                      backgroundColor: item
                    }}>
                      <CheckOutlined />
                    </Color>
                  );
                })}
              </ColorList>
            </Modal>
          </ColorSchema>
        );
      }}
    </Field>
  );
};

const ColorSchema = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColorList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const Color = styled.div`
  display: block;
  width: 46px;
  height: 46px;
  border: 0;
  border-radius: 4px;
  text-align: center;
  margin: 4px;
  cursor: pointer;
  &.small {
    width: 32px;
    height: 32px;
  }
  &.active {
    .anticon {
      display: block;
    }
  }
  &.white {
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    .anticon {
      color: #979797;
    }
  }
  .anticon {
    font-size: 24px;
    line-height: 52px;
    color: #ffff;
    display: none;
  }
`;