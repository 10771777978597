/* eslint-disable radix */
import React from "react";
import { FormItem, Input, Radio, Select } from "formik-antd";
import { useTranslate } from "../../../hooks";
import ImageUpload from "../../../components/ImageUpload";
import { Content, FormfieldSection } from "./DeliveryMessageForm";
import { Button, Space, Spin, Tabs } from "antd";
import styled from "styled-components";
import { CloseOutlined, LeftOutlined, RightOutlined, SnippetsOutlined } from "@ant-design/icons";
import Upload from "./Upload";
import ActionField from "./ActionField";
import CarouselActionField from "./CarouselActionField";

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

export default ({ onReload, values, setFieldValue, setValues, setFieldTouched })=> {
  const { translate } = useTranslate();
  const [key, setKey] = React.useState(0);
  const [loading, setLoading]= React.useState(false);
  const disabled = !values.sliderArea?.isSliderArea;

  const onEdit =(targetKey, action, setFieldValue, values)=> {
    console.log(action);
    if (action === "add"){
      if (values.sliderArea.items.length < 9){
        let add = [...values.sliderArea.items, {}];
        setFieldValue("sliderArea.items", add);
        setKey(add.length-1);
      }
    }
    if (action === "remove"){
      let items = values.sliderArea.items;
      items.splice(targetKey, 1);
      setFieldValue("sliderArea.items", [...items]);
      setKey(items.length-1);
    }
  };

  const copy =(values, setFieldValue)=> {
    let index = values.sliderArea.items.length -1;
    index +=1;
    if (index < 9){
      setKey(index);
      setFieldValue(`sliderArea.items[${index}]`, values.sliderArea.items[key]);
    }
  };

  const onSort = (action, values, setFieldValue) => {
    let colmns = [...values.sliderArea.items];
    let index = key;
    if (action === "up"){
      index = parseInt(key) +1;
      colmns[index] = values.sliderArea.items[key];
      colmns[key] = values.sliderArea.items[index];
      setFieldValue("sliderArea.items", [...colmns]);
      setKey(index);
    }
    if (action === "down"){
      index = parseInt(key) -1;
      colmns[index] = values.sliderArea.items[key];
      colmns[key] = values.sliderArea.items[index];
      setFieldValue("sliderArea.items", [...colmns]);
      setKey(index);
    }
  };

  const renderCarousel =(values, setFieldValue)=> {
    return (
      <div>
        <CarouselController>
          <Button disabled={disabled} type="text" onClick={()=>{ copy(values, setFieldValue); }}>
            <SnippetsOutlined />
          </Button>
          <Button disabled={parseInt(key) === 0 || disabled} type="text" onClick={()=>{ onSort("down", values, setFieldValue); }}>
            <LeftOutlined />
          </Button>
          <Button disabled={(parseInt(key) + 1 || disabled) >= values.sliderArea.items.length} type="text" onClick={()=>{
            onSort("up", values, setFieldValue);
          }}>
            <RightOutlined />
          </Button>
          <Button disabled={values.sliderArea.items.length === 1 || disabled} type="text" onClick={()=>{
            if (key > 0){
              let colmns = values.sliderArea.items;
              colmns.splice(key, 1);
              setFieldValue("sliderArea.items", [...colmns]);
              setKey(colmns.length-1);
              if (colmns.length === 0){
                setFieldValue("type", undefined);
              }
            }
          }}><CloseOutlined /></Button>
        </CarouselController>
        <CarouselContent>
          <FormfieldSection title={translate("sliderArea.carousel.image", "Image")}>
            <FormItem name={`sliderArea.items[${key}].image`} required>
              <Upload disabled={disabled} action="/api/carousel/upload" imageLoading={`sliderArea.items[${key}].image`} name={`sliderArea.items[${key}].image`} placeholder={translate("sliderArea.carousel.image.placeholder", "Upload card image")}/>
            </FormItem>
          </FormfieldSection>
          <FormfieldSection title={translate("sliderArea.carousel.text", "Text")}>
            <FormItem name={`sliderArea.items[${key}].text`} required>
              <Input disabled={disabled} name={`sliderArea.items[${key}].text`} placeholder={translate("sliderArea.carousel.text.placeholder", "Text")} />
            </FormItem>
          </FormfieldSection>
          <FormfieldSection title={translate("sliderarea.action", "Action")} >
            <FormItem label={translate("sliderarea.actiontype", "Action type")} name={`sliderArea.items[${key}].actionType`} required>
              <Select onChange={() => {
                setFieldValue(`sliderArea.items[${key}].isTagged`, false);
                setFieldValue(`sliderArea.items[${key}].tagType`, 0);
                setFieldValue(`sliderArea.items[${key}].couponCode`, undefined);
                setFieldValue(`sliderArea.items[${key}].taggedUrl`, undefined);
                setFieldValue(`sliderArea.items[${key}].url`, undefined);
                setFieldValue(`sliderArea.items[${key}].additionalParameter`, undefined);
                setFieldValue(`sliderArea.items[${key}].externalBrowser`, undefined);
              }} disabled={disabled} style={{ marginBottom: "25px", width: "100%" }} name={`sliderArea.items[${key}].actionType`} placeholder={translate("system.select", "Select")} >
                <Option value="URL" >{translate("delivery.action.link", "Link")}</Option>;
                <Option value="COUPON" > {translate("delivery.action.coupon", "Coupon")}</Option>;
              </Select>
            </FormItem>
            <CarouselActionField
              disabled={disabled}
              setFieldValue={setFieldValue}
              setFieldTouched={ setFieldTouched }
              values={values}
              index={key}
            />
          </FormfieldSection>
        </CarouselContent>
      </div>
    );
  };

  return (
    <div>
      <Content>
        <div className="content">
          <FormfieldSection title={translate("sliderarea.type", "Type")}>
            <Space style={{ marginBottom: 10 }} >
              <Radio.Group name="sliderArea.type" defaultValue={"SIMPLE"}>
                <Radio.Button value="SIMPLE">{translate("sliderarea.type.simple", "Simple")}</Radio.Button>
                <Radio.Button value="CAROUSEL">{translate("sliderarea.type.carousel", "Carousel")}</Radio.Button>
                <Radio.Button value="HTML">HTML</Radio.Button>
              </Radio.Group>
            </Space>
          </FormfieldSection>
          {(() => {
            switch (values.sliderArea.type) {
              case "HTML":
                return (
                  <FormfieldSection title={translate("sliderarea.html", "HTML")}>
                    <FormItem name="sliderArea.html" required>
                      <TextArea disabled={disabled} name="sliderArea.html" placeholder={translate("sliderarea.html.placeholder", "Insert html here")} rows={6} />
                    </FormItem>
                  </FormfieldSection>
                );
              case "SIMPLE":
                return (
                  <div>
                    <FormfieldSection title={translate("sliderarea.title", "Title")}>
                      <FormItem name="sliderArea.title" required>
                        <Input disabled={disabled} name="sliderArea.title" placeholder={translate("sliderarea.title.placeholder", "Title")} />
                      </FormItem>
                    </FormfieldSection>
                    <FormfieldSection title={translate("sliderarea.image", "Image")}>
                      <FormItem name="sliderArea.image" required>
                        <ImageUpload disabled={disabled} name="sliderArea.image" size="small" action="/api/general/upload" />
                      </FormItem>
                    </FormfieldSection>
                    <FormfieldSection title={translate("sliderarea.action", "Action")} >
                      <FormItem label={translate("sliderarea.actiontype", "Action type")} name="sliderArea.actionType">
                        <Select onChange={() => {
                          setFieldValue("sliderArea.isTagged", false);
                          setFieldValue("sliderArea.tagType", 0);
                          setFieldValue("sliderArea.taggedUrl", undefined);
                          setFieldValue("sliderArea.url", undefined);
                          setFieldValue("sliderArea.couponCode", undefined);
                          setFieldValue("sliderArea.additionalParameter", undefined);
                          setFieldValue("sliderArea.externalBrowser", undefined);
                        }} disabled={disabled} style={{ marginBottom: "25px", width: "100%" }} name={"sliderArea.actionType"} placeholder={translate("system.select", "Select")} >
                          <Option value="URL" >{translate("delivery.action.link", "Link")}</Option>;
                          <Option value="COUPON" > {translate("delivery.action.coupon", "Coupon")}</Option>;
                        </Select>
                      </FormItem>
                      <ActionField
                        setFieldValue={setFieldValue}
                        setFieldTouched={ setFieldTouched }
                        disabled={disabled}
                        area="sliderArea"
                        values={values}
                        linkUri='sliderArea.url'
                        actionType='sliderArea.actionType'
                        externalBrowser='sliderArea.externalBrowser'
                        isTagged='sliderArea.isTagged'
                        tagType='sliderArea.tagType'
                        taggedUrl='sliderArea.taggedUrl'
                        couponCode='sliderArea.couponCode'
                        additionalParameter='sliderArea.additionalParameter'
                      />
                    </FormfieldSection>
                  </div>
                );
              case "CAROUSEL":
                return <div>
                  <Tabs
                    activeKey={`${key}`}
                    tabBarExtraContent={<Button onClick={()=> {
                      onEdit(null, "add", setFieldValue, values);
                    }}>{translate("system.add", "Add card")}</Button>}
                    onChange={(key)=> {
                      setKey(key);
                    }}
                    onEdit={(targetKey, action)=> onEdit(targetKey, action, setFieldValue, values)}
                    type="card"
                  >
                    {[...Array.from({ length: values?.sliderArea.items?.length > 0 ? values?.sliderArea.items?.length : 1 }, (v, i) => i)].map(i => (
                      <TabPane key={i} disabled={i === 28} tab={i +1}/>
                    ))}
                  </Tabs>
                  {renderCarousel(values, setFieldValue, setValues)}
                </div>;
              default:
            }
          })()}
        </div>
      </Content>
    </div>
  );
};

const CarouselController = styled.div`
  width : 100%;
  display:flex;
  justify-content:end;
`;
const CarouselContent = styled.div`
  width : 100%;
`;