import React from "react";
import { Upload, message } from "antd";
import { Field } from "formik";
import { CloseOutlined, LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../colors";
import { useTranslate } from "../../../hooks";
import ImgCrop from "antd-img-crop";


const FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"];



export default ({ size, name, action, imageLoading, type, ...restProps }) => {


  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState(false);

  const onRemove = ({ setFieldValue }) => {
    setFieldValue(name, undefined);
  };

  const onBeforeUpload = async (file, translate, setFieldValue, setFieldTouched) => {

    const isJpgOrPng = FILE_TYPES.indexOf(file.type) !== -1;

    if (!isJpgOrPng) {
      message.error(translate("message.error.fileformat", "You can only upload JPG/PNG file!"));
    }
    setFieldTouched(name, true);
    if (!isJpgOrPng){
      return false;
    }
  };


  const onChange =(e, setFieldValues)=> {
    console.log(e);
    if (e.file.response){
      setFieldValues(name, e.file.response.url);
    }
  };

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value, errors },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;
        console.log("error => ", errors);
        return (
          <UploadContainer className={`${size}`}>
            {value && !loading ? (
              <Uploaded>
                <img src={value} alt='avatar' style={{ width: "100%" }} />
                <a className="close" href="#" onClick={() => onRemove({ setFieldValue })}><CloseOutlined /></a>
              </Uploaded>
            ) : (
              // ff4c4f
              <ImgCrop
                aspect={1/1}
                modalTitle={translate("carousel.image.upload", "Image upload")}
                rotate>
                <Upload
                  name='file'
                  action={action}
                  showUploadList={false}
                  accept={FILE_TYPES}
                  onBeforeUpload={(e) => {onBeforeUpload(e, translate, setFieldValue, setFieldTouched);}}
                  onChange={(e)=>onChange(e, setFieldValue)}
                >
                  <SelectFile className="file-select">
                    {loading ? <LoadingOutlined /> : <PictureOutlined />}
                    <div className="upload-text">{translate("imagemap.upload", "Upload Image")}</div>
                  </SelectFile>
                </Upload>
                <p>
                  {translate("imagemap.fileformat", "File formats: ")}JPG, JPEG, PNG <br />
                  {translate("message.filesize", "File size:")} 10 MB
                </p>
              </ImgCrop>
            )}
          </UploadContainer>
        );
      }}
    </Field>
  );
};


const SelectFile = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  border: 1px dashed #ddd;
  /* #ff4c4f */
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;
const Uploaded = styled.div`
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #c7c7c7;
  img {
    display: flex;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
const UploadContainer = styled.div`
  .ant-form-item-has-error {
    .ant-upload {
      border-color: red!important;
    }
  }
  &.small {
    height: 140px!important;
    .ant-upload { 
      width: 140px!important; 
    }
  }
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;