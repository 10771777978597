import React from "react";
import { FormItem, Input, Select } from "formik-antd";
import { useTranslate } from "../../../hooks";
import ImageUpload from "../../../components/ImageUpload";
import { Content, FormfieldSection } from "./DeliveryMessageForm";
import { Col, Row } from "antd";
import { ColorSchema } from "../../../components";
import ActionField from "./ActionField";

const { TextArea } = Input;
const { Option } = Select;

export default ({ onReload, values, setFieldValue, setFieldTouched })=> {
  const { translate } = useTranslate();
  const disabled = !values.freeArea?.isFreeArea;
  return (
    <div>
      <Content>
        <div className="content">
          <FormfieldSection title={translate("freearea.title", "Title")}>
            <FormItem name="freeArea.title" required>
              <Input disabled={disabled} name="freeArea.title" placeholder={translate("freearea.title.placeholder", "Title")} />
            </FormItem>
          </FormfieldSection>
          <FormfieldSection title={translate("freearea.image", "Image")}>
            <FormItem name="freeArea.image" required>
              <ImageUpload disabled={disabled} name="freeArea.image" size="small" action="/api/general/upload" />
            </FormItem>
          </FormfieldSection>
          <FormfieldSection title={translate("freearea.text", "Text")}>
            <FormItem name="freeArea.text" required>
              <TextArea name="freeArea.text" placeholder={translate("freearea.text.placeholder", "Text")} rows={4} />
            </FormItem>
          </FormfieldSection>
          <FormfieldSection title={translate("freearea.button", "Button")}>
            <Row gutter={15}>
              <Col>
                <FormItem name="freeArea.buttonText" label={translate("freearea.buttontext", "Label")} required>
                  <Input disabled={disabled} name="freeArea.buttonText" placeholder={translate("freearea.buttontext.placeholder", "Label")} />
                </FormItem>
              </Col>
              <Col>
                <FormItem label={translate("freearea.buttontextcolor", "Text color")} name="freeArea.buttonTextColor">
                  <ColorSchema disabled={disabled} name="freeArea.buttonTextColor" />
                </FormItem>
              </Col>
              <Col>
                <FormItem label={translate("freearea.buttoncolor", "Background color")} name="freeArea.buttonColor">
                  <ColorSchema disabled={disabled} name="freeArea.buttonColor" />
                </FormItem>
              </Col>
            </Row>
          </FormfieldSection>
          <FormfieldSection title={translate("recruitarea.action", "Action")} >
            <FormItem label={translate("recruitarea.actiontype", "Action type")} name="freeArea.actionType">
              <Select onChange={() => {
                setFieldValue("freeArea.isTagged", false);
                setFieldValue("freeArea.tagType", 0);
                setFieldValue("freeArea.taggedUrl", undefined);
                setFieldValue("freeArea.couponCode", undefined);
                setFieldValue("freeArea.url", undefined);
                setFieldValue("freeArea.additionalParameter", undefined);
                setFieldValue("freeArea.externalBrowser", undefined);
              }}
              disabled={disabled}
              name={"freeArea.actionType"}
              placeholder={translate("system.select", "Select")}
              >
                <Option value="URL" >{translate("delivery.action.link", "Link")}</Option>;
                <Option value="COUPON" > {translate("delivery.action.coupon", "Coupon")}</Option>;
              </Select>
            </FormItem>
            <ActionField
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              area="freeArea"
              disabled={disabled}
              values={values}
              linkUri='freeArea.url'
              actionType='freeArea.actionType'
              externalBrowser='freeArea.externalBrowser'
              isTagged='freeArea.isTagged'
              tagType='freeArea.tagType'
              taggedUrl='freeArea.taggedUrl'
              couponCode='freeArea.couponCode'
              additionalParameter='freeArea.additionalParameter'
            />
          </FormfieldSection>

        </div>
      </Content>
    </div>
  );
};