import request from "../utils/request";

export const list = (data) => request.get("/api/notifmessage", data);
export const report = (data) => request.get("/api/notifmessage_report", data);
export const summary = (data) => request.get("/api/notifmessage_summary", data);
export const get = (type) => request.get(`/api/notifmessage/${type}`);
export const status = (id) => request.put(`/api/notifmessage/status/${id}`);
export const apply = (data) => request.post("/api/notifmessage/apply", data);
export const update = (data) => request.put(`/api/notifmessage/${data.type}`, data);
export const preview = (data) => request.post(`/api/notifmessage/${data.type}/preview`, data);
export const send = (data) => request.post("/api/notifmessage/send", data);
