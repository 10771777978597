import request from "../utils/request";

export const create = (data) => request.post("/api/account", data);
export const rakutenAccessShow = (data) => request.post("/api/account/rakuten_access_show", data);
export const update = (data) => request.put(`/api/account/${data._id}`, data);
export const ecmCreate = (data) => request.put(`/api/account/ecm_create/${data._id}`, data);
export const ecmSync = (id) => request.put(`/api/account/ecmsync/${id}`);
export const ecmDisconnect = (data) => request.post(`/api/account/ecm/${data.id}/disconnect`, data);
export const change = (id) => request.post(`/api/account/change/${id}`);
export const get = (id) => request.get(`/api/account/${id}`);
export const stickers = () => request.get("/api/account/stickers");
export const setting = () => request.get("/api/account/setting");
export const remove = (id) => request.del(`/api/account/${id}`);

export const ecm = (data) => request.put(`/api/account/ecm/${data.id}`, data);
export const rakurakuCon = (id, data) => request.put(`/api/account/rakuraku/${id}`, data);
export const rakurakuDiscon = (id) => request.del(`/api/account/rakuraku/${id}`);
export const shopname = (data) => request.post("/api/account/shopname", data);