import React from "react";
import { useTranslate } from "../../hooks";
import { coupon as couponApi } from "../../apis";
import { useHistory } from "react-router";
import { Empty, Button, Tag, Select, DatePicker, Space, Modal, Input } from "antd";
import { MyTable } from "../../components";
import Avatar from "antd/lib/avatar/avatar";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import colors from "../../colors";
import locale from "antd/es/date-picker/locale/ja_JP";
import { PageContent } from "../../components/Layout";
import { SearchOutlined } from "@ant-design/icons";
import { Field } from "formik";
import { delay } from "../RichMenuAlias/Form";


export default ({ name, urlField, onChange, image, schedules, disabled })=> {

  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [type, setType] = React.useState("couponName");
  const [publicMethods, setPublicMethods] = React.useState({
    setFieldValue: ()=>{}
  });
  const { lang, rakutenCouponStatuses } = useSelector(state => state.general);

  const [couponStatuses, setCouponStatuses] = React.useState(null);
  const [filters, setFilters] = React.useState({
    query: ""
  });
  const { Option } = Select;


  const onSelect =async (row)=> {
    publicMethods.setFieldValue(name, `${row.couponCode}`);
    publicMethods.setFieldValue(urlField, `${row.pcGetUrl}`);
    publicMethods.setFieldValue(image, `${row.couponImage}`);
    publicMethods.setFieldValue(schedules, [row.couponStartDate, row.couponEndDate]);
    if (onChange){
      onChange(null);
      await delay(100);
      onChange(`${row.couponCode}`);
    }
    onCancel();
  };

  const onCancel =(row)=> {
    setAction(null);
  };

  const convertArrayToObject = (array, key) => {
    return array.reduce((accumulator, iterator) => {
      return {
        ...accumulator,
        [iterator[key]]: iterator,
      };
    }, {});
  };

  const columns = useHeader({
    history,
    onSelect,
    lang,
    tableTitles: {
      id          : translate("system.number", "No."),
      couponImage : translate("coupon.image", "Coupon image"),
      couponStatus: translate("system.status", "Status"),
      couponCode  : translate("coupon.code", "Coupon code"),
      startDate   : translate("coupon.startdate", "Start date"),
      endDate     : translate("coupon.enddate", "End date"),
      select      : translate("system.select", "select"),
    },
    renderStatus: (type, value)=> {
      switch (type){
        case "NEW":
          return (<Tag color="green">{value}</Tag>);
        case "STARTED":
          return (<Tag color="orange">{value}</Tag>);
        case "ENDED":
          return (<Tag color="red">{value}</Tag>);
        default:
          return "";
      }
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          break;
        }
        default:
      }
    },
    translate,
    couponStatuses
  });


  React.useEffect(() => {
    if (rakutenCouponStatuses?.length > 0){
      setCouponStatuses(convertArrayToObject(rakutenCouponStatuses, "code"));
    }
  }, [rakutenCouponStatuses]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query, type: type });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);


  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field :{ value },
          form: { setFieldValue, setFieldTouched, setFieldError, fieldTouch, fieldError }
        } = fieldProps;
        return (
          <div>
            <div>
              <Input.Group compact >
                <Button disabled={disabled} style={{ width: "calc(30% - 30px)" }} onClick={()=> {
                  setAction(["coupon"]);
                  setPublicMethods({
                    setFieldValue: setFieldValue
                  });
                }}>{translate("system.coupon.select", "Coupon select")}</Button>
                <Input
                  className="site-input-split"
                  style={{
                    width        : 30,
                    borderRight  : 0,
                    pointerEvents: "none",
                  }}
                  placeholder="~"
                  disabled
                />
                <Input value={value} style={{ width: "70%", cursor: "pointer", background: "#fff", color: "#000" }} placeholder={translate("imagemap.action.coupon.code", "Coupon code")} disabled />
              </Input.Group>
            </div>

            <Modal
              width={800}
              maskClosable ={false}
              title={translate("coupon.list", "Coupon list")}
              visible={action && action[0] === "coupon"}
              onCancel={(()=> {setAction(null);})}
              destroyOnClose
              footer={false}>
              <MessageFilter>
                <Space>
                  <Input.Group compact>
                    <Select onChange={(e) => setType(e)} defaultValue={type}>
                      <Option value="couponName" > {translate("coupon.name", "Coupon name")}</Option>
                      <Option value="couponCode">{translate("coupon.code", "Coupon code")}</Option>
                      <Option value="startDate">{translate("coupon.startdate", "Start date")}</Option>
                    </Select>
                    {((() => {
                      if (type === "startDate") {
                        return (
                          <DatePicker
                            locale={lang === "JP" ? locale : null}
                            style={{ width: 250 }}
                            onChange={(e) => { setQuery(e); }}
                            picker="month" />
                        );
                      }

                      return (
                        <Input
                          name="query"
                          placeholder={translate("system.search.button", "Search...")}
                          onChange={e => setQuery(e.target.value)}
                          style={{ width: 250 }}
                          prefix={<SearchOutlined />}
                        />
                      );

                    }))()}
                  </Input.Group>
                </Space>
              </MessageFilter>

              <PageContent>
                <MyTable
                  scroll={{ x: 0 }}
                  locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
                  ref={myTableRef}
                  filters={filters}
                  limit ={10}
                  rowKey={(record) => record._id}
                  columns={columns}
                  loadData={couponApi.list}
                />
              </PageContent>
            </Modal>
          </div>
        );
      }}
    </Field>
  );
};

const useHeader = ({ onAction, tableTitles, onSelect, renderStatus, couponStatuses, translate, lang }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.couponImage,
    render: (record) => {
      return <Avatar shape="square" size={94} src={`${record.couponImage}`} />;
    },
  }, {
    title : tableTitles.couponCode,
    render: (record) => {
      return (
        <Container>
          <div>&bull; {record.couponName}</div>
          <div>&bull; {record.couponCode}</div>
          <div>&bull; {moment(record.couponStartDate).format("YYYY-MM-DD HH:mm")} - {moment(record.couponEndDate).format("YYYY-MM-DD HH:mm")}</div>
        </Container>
      );
    },
  }, {
    title : tableTitles.couponStatus,
    render: (record) => {
      if (Object.keys(couponStatuses).length > 0){
        return (
          <div>
            {renderStatus(record.couponStatus, couponStatuses[record.couponStatus][lang.toLowerCase()])}
            {record.isAutoIssue === true && <Tag color="cyan">{translate("coupon.isAutoIssue", "Auto issue")}</Tag>}
          </div>
        );
      }
    },
  }, {
    title : tableTitles.select,
    key   : "action",
    width : 100,
    render: (row) => {
      return (
        <Button onClick={()=>onSelect(row)}>{translate("system.select", "Select")}</Button>
      );
    },
  }];
};
const Container =styled.div`
  display : flex;
  width   : 100%;
  flex-direction: column;;
`;

const MessageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;