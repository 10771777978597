import React from "react";
import { Checkbox, Form, FormItem, Input, } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Collapse, Row, Col, message, TimePicker, Alert } from "antd";
import { useTranslate } from "../../../hooks";
import NotifMessagePreview from "../../../components/NotifMessagePreview";
import FlexMessagePreview from "../../../components/FlexMessagePreview";
import styled from "styled-components";
import ColorSchema from "../../../components/ColorSchema";
import colors from "../../../colors";
import { notifMessageApi } from "../../../apis";
import { useParams } from "react-router-dom";
import FreeAreaForm from "./FreeAreaForm";
import RecruitAreaForm from "./RecruitAreaForm";
import SliderAreaForm from "./SliderAreaForm";
import { URL_REGEX } from "../../Imagemap/image/components/Form";
import { useSelector } from "react-redux";
import moment from "moment";

const { Panel } = Collapse;
const { RangePicker } = TimePicker;

const FormSchema = (translate) => Yup.object().shape({
  headerColor    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  headerTextColor: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  buttonColor    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  buttonTextColor: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  type           : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  isDelay        : Yup.boolean(),
  startTime      : Yup.string().when("isDelay", {
    is  : true,
    then: Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
  }).optional().nullable(),
  endTime: Yup.string().when("isDelay", {
    is  : true,
    then: Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
  }).optional().nullable(),
  recruitArea: Yup.object().shape({
    isRecruitArea: Yup.boolean(),
    title        : Yup.string().when("isRecruitArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    image: Yup.string().when("isRecruitArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    text: Yup.string().when("isRecruitArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    buttonText: Yup.string().when("isRecruitArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    url: Yup.string().matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("isRecruitArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    actionType: Yup.string().when("isRecruitArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    couponCode: Yup.string().when(["actionType", "isRecruitArea"], {
      is  : (actionType, isRecruitArea) => actionType === "COUPON" && isRecruitArea,
      then: Yup.string().required(translate("system.message.required", "Required!"))
    }),
    isTagged : Yup.boolean().nullable(),
    taggedUrl: Yup.string().when("isRecruitArea", {
      is  : true,
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    })
  }),
  sliderArea: Yup.object().shape({
    isSliderArea: Yup.boolean(),
    type        : Yup.string(),
    title       : Yup.string().when(["isSliderArea", "type"], {
      is  : (isSliderArea, type) => isSliderArea === true && type === "SIMPLE",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    image: Yup.string().when(["isSliderArea", "type"], {
      is  : (isSliderArea, type) => isSliderArea === true && type === "SIMPLE",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    url: Yup.string().matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when(["isSliderArea", "type"], {
      is  : (isSliderArea, type) => isSliderArea === true && type === "SIMPLE",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    html: Yup.string().when(["isSliderArea", "type"], {
      is  : (isSliderArea, type) => isSliderArea === true && type === "HTML",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    actionType: Yup.string().when(("isSliderArea", "type"), {
      is  : (isSliderArea, type) => isSliderArea && type === "SIMPLE",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    couponCode: Yup.string().when(["actionType", "isSliderArea", "type"], {
      is  : (actionType, isSliderArea, type) => actionType === "COUPON" && isSliderArea && type === "SIMPLE",
      then: Yup.string().required(translate("system.message.required", "Required!"))
    }),
    isTagged : Yup.boolean().nullable(),
    taggedUrl: Yup.string().when(["isSliderArea", "type"], {
      is  : (isSliderArea, type) => isSliderArea && type === "SIMPLE",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    }),
    items: Yup.array().of(Yup.object({
      image: Yup.string(),
      text : Yup.string(),
      url  : Yup.string(),
    })).when(["isSliderArea", "type"], {
      is  : (isSliderArea, type) => isSliderArea === true && type === "CAROUSEL",
      then: Yup.array().of(Yup.object({
        image     : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
        text      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
        url       : Yup.string().matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).required(translate("system.message.required", "Please fill out this field!")),
        actionType: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
        couponCode: Yup.string().when("actionType", {
          is  : "COUPON",
          then: Yup.string().required(translate("system.message.required", "Required!"))
        }),
        isTagged : Yup.boolean().nullable(),
        taggedUrl: Yup.string().when("isTagged", {
          is  : true,
          then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
        }).nullable()
      })),
    })
  }),
  freeArea: Yup.object().shape({
    isFreeArea: Yup.boolean(),
    title     : Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    image: Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    text: Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    buttonText: Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    buttonTextColor: Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    buttonColor: Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    url: Yup.string().matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    actionType: Yup.string().when("isFreeArea", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    }),
    couponCode: Yup.string().when(["actionType", "isFreeArea"], {
      is  : (actionType, isFreeArea) => actionType === "COUPON" && isFreeArea,
      then: Yup.string().required(translate("system.message.required", "Required!"))
    }),
    isTagged : Yup.boolean().nullable(),
    taggedUrl: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).when("isFreeArea", {
      is  : true,
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    })
  })
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();
      console.log(errors);
      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { deliveryMessage } = props;
  const { type } = useParams();
  const { translate } = useTranslate();
  const [collapse, setCollapse] = React.useState(["message", "forms", "recruit", "slider", "free"]);
  const { account } = useSelector(state => state.general);
  const submitRef = React.useRef();
  const [data] = React.useState({
    shopName       : account?.shopName,
    type           : deliveryMessage?.type,
    headerColor    : deliveryMessage?.headerColor || "#c00001",
    headerTextColor: deliveryMessage?.headerTextColor || "#ffffff",
    buttonColor    : deliveryMessage?.buttonColor || "#389d4a",
    buttonTextColor: deliveryMessage?.buttonTextColor || "#ffffff",
    isDelay        : deliveryMessage?.isDelay || false,
    startTime      : (deliveryMessage?.startTime) || null,
    endTime        : (deliveryMessage?.endTime) || null,
    recruitArea    : {
      isRecruitArea      : false,
      actionType         : "URL",
      title              : "",
      image              : "",
      text               : "",
      buttonText         : "",
      url                : undefined,
      externalBrowser    : false,
      isTagged           : false,
      tagType            : 0,
      taggedUrl          : undefined,
      couponCode         : undefined,
      additionalParameter: undefined,
      ...(deliveryMessage.recruitArea || {}),
    },
    sliderArea: {
      isSliderArea: false,
      type        : "SIMPLE",
      actionType  : "URL",
      title       : "",
      image       : "",
      url         : "",
      html        : "",
      items       : [{
        image              : undefined,
        text               : undefined,
        url                : undefined,
        externalBrowser    : false,
        isTagged           : false,
        tagType            : 0,
        taggedUrl          : undefined,
        couponCode         : undefined,
        additionalParameter: undefined,
      }],
      ...(deliveryMessage.sliderArea || {}),
    },
    freeArea: {
      isFreeArea         : false,
      actionType         : "URL",
      title              : "",
      image              : "",
      text               : "",
      buttonText         : "",
      buttonTextColor    : "",
      buttonColor        : "",
      url                : undefined,
      externalBrowser    : false,
      isTagged           : false,
      tagType            : 0,
      taggedUrl          : undefined,
      couponCode         : undefined,
      additionalParameter: undefined,
      ...(deliveryMessage.freeArea || {}),
    },
    // ...(deliveryMessage || {}),
  });

  const onChangeCollapse = (collapse) => {
    setCollapse(collapse);
  };

  const onReload = () => async () => {

    let values = await submitRef.current.submitForm();

    if (!values) return;

    let link = await notifMessageApi.preview({
      type,
      ...values
    });

    return link;
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();

      if (!values) message.error(translate("system.error.required", "Insert necessary fields first!"));

      return values;
    }
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema(translate)}
      onSubmit={() => { }}>
      {({ values, setFieldValue, setValues, errors, setFieldTouched }) => {
        return (
          <Form layout="vertical">
            <Container>
              <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>
                <Panel key="message" header={translate("deliverymessage.flex.panel.title", "Flex message template")}>
                  <Content>
                    <FlexMessagePreview type={type} theme="light" onReload={() => (values)} />
                    <div className="content">
                      <FormfieldSection title={translate("deliverymessage.form.header", "Header")}>
                        <Row gutter={15}>
                          <Col>
                            <FormItem label={translate("deliverymessage.form.header.textcolor", "Text color")} name="headerTextColor">
                              <ColorSchema name="headerTextColor" />
                            </FormItem>
                          </Col>
                          <Col>
                            <FormItem label={translate("deliverymessage.form.header.backgroundcolor", "Background color")} name="headerColor">
                              <ColorSchema name="headerColor" />
                            </FormItem>
                          </Col>
                        </Row>
                      </FormfieldSection>
                      <FormfieldSection title={translate("deliverymessage.form.button", "Button")}>
                        <Row gutter={15}>
                          <Col>
                            <FormItem label={translate("deliverymessage.form.button.textcolor", "Text color")} name="buttonTextColor">
                              <ColorSchema name="buttonTextColor" />
                            </FormItem>
                          </Col>
                          <Col>
                            <FormItem label={translate("deliverymessage.form.button.backgroundcolor", "Background color")} name="buttonColor">
                              <ColorSchema name="buttonColor" />
                            </FormItem>
                          </Col>
                        </Row>
                      </FormfieldSection>

                      <FormfieldSection title={translate("deliverymessage.form.shopname", "Shop name")}>
                        <FormItem name="shopName" required>
                          <Input disabled name="shopName" placeholder={translate("deliverymessage.form.shopname", "Shop name")} />
                        </FormItem>
                      </FormfieldSection>

                      <FormfieldSection title={translate("deliverymessage.form.sendtime", "Send times")}>
                        <FormItem className="checkbox" name="isDelay">
                          <Checkbox onChange={() => {
                            setFieldValue("startDate", null);
                            setFieldValue("endDate", null);
                          }} style={{ alignItems: "start" }} name="isDelay">{
                              <div>
                                {translate("deliverymessage.form.isdelay", "Delay time")}
                              </div>
                            }</Checkbox>
                        </FormItem>
                        {values.isDelay &&
                        <FormItem
                          label={translate("deliverymessage.form.time", "Time")} name={"startTime"} key='startTime' >
                          <RangePicker
                            minuteStep={10}
                            style={{ borderColor: (errors.startTime && errors.endTime) ? "#ff4d4f" : "" }}
                            value={[values.startTime ? moment(values.startTime):undefined, values.endTime ? moment(values.endTime):undefined]}
                            format={(date) => date.tz("Asia/Tokyo").format("HH:mm")}
                            showSecond={false}
                            onChange= {(value) => {
                              if (value) {
                                setFieldValue("startTime", value[0]);
                                setFieldValue("endTime", value[1]);
                              } else {
                                setFieldValue("startTime", undefined);
                                setFieldValue("endTime", undefined);
                              }
                            }}
                          />
                          {errors.startTime && errors.endTime && <div className="ant-form-item-explain ant-form-item-explain-error">{errors.startTime}</div>}
                          <Alert style={{ marginTop: 10 }} type="warning" message={
                            <div>
                              {translate("deliverymessage.manual.alert.delaytime", "If it takes a long time to send the notification even after the notification end time, it may be sent.")}
                            </div>
                          } />
                        </FormItem>
                        }
                      </FormfieldSection>
                    </div>
                  </Content>
                </Panel>
                <Panel key="forms" header={translate("deliverymessage.form.panel.header", "Page creator")}>
                  <Content>
                    <NotifMessagePreview theme="light" onReload={onReload()} />
                    <div className="content">
                      <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>
                        <Panel key="recruit" header='フリーエリア1の編集' extra={
                          <div onClick={(event) => {
                            event.stopPropagation();
                          }}>
                            <FormItem style={{ marginBottom: 0 }}name="recruitArea.isRecruitArea">
                              <Checkbox name="recruitArea.isRecruitArea" />{" "}{translate("deliverymessage.form.isrecruitarea", "Recruit area")}
                            </FormItem>
                          </div>
                        }>
                          <RecruitAreaForm setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} onReload={onReload} values={values} />
                        </Panel>
                        <Panel key="slider" header='フリーエリア2の編集' extra={
                          <div onClick={(event) => {
                            event.stopPropagation();
                          }}>
                            <FormItem style={{ marginBottom: 0 }}name="sliderArea.isSliderArea">
                              <Checkbox name="sliderArea.isSliderArea" />{" "}{translate("deliverymessage.form.sliderarea", "Slider area")}
                            </FormItem>
                          </div>
                        }>
                          <SliderAreaForm setFieldTouched={setFieldTouched} setValues={setValues} setFieldValue={setFieldValue} onReload={onReload} values={values} />
                        </Panel>
                        {type === "DELIVERY" && <Panel key="free" header={"フリーエリア3の編集"} extra={
                          <div onClick={(event) => {
                            event.stopPropagation();
                          }}>
                            <FormItem style={{ marginBottom: 0 }}name="freeArea.isFreeArea">
                              <Checkbox name="freeArea.isFreeArea" />{" "}{translate("deliverymessage.form.freearea", "Free area")}
                            </FormItem>
                          </div>
                        }>
                          <FreeAreaForm setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} onReload={onReload} values={values} />
                        </Panel>}
                      </Collapse>
                    </div>
                  </Content>
                </Panel>
              </Collapse>
            </Container>
            <SubmitForm ref={submitRef} />
            {/* {JSON.stringify(errors, null, 2)} */}
          </Form>
        );
      }}
    </Formik>
  );
});

export const FormfieldSection = (props) => {
  return (
    <FormFieldContent>
      <div className="label">{props.title}</div>
      <div className="content">{props.children}</div>
    </FormFieldContent>
  );
};


export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  .content {
    padding-left: 40px;
  }
`;
export const PanelHeader = styled.div`
  display: flex;
`;
const FormFieldContent = styled.div`
  display: flex;
  flex-direction: row;
  .label {
    font-size: 15px;
    color: #333;
    width: 120px;
    font-weight: 500;
  }
  .content {
    padding: 0;
    .checkbox {
      .ant-form-item-control-input {
        align-items: start;
      }
    }
  }
`;
const Container = styled.div`
 .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    padding-left: 20px!important;
    .anticon {
      left: 0!important;
    }
  }
  .ant-collapse-content-box {
    padding: 20px 0!important;
  }
`;
export const WriteMessage = styled.div`
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 20px;
  .ant-radio-button-wrapper {
    &:hover {
      color: ${colors.primary};
    }
  }
  .ant-radio-button-wrapper-checked {
    &:hover {
      color: ${colors.primary}!important;
    }
    color: ${colors.primaryDark};
    border-color: ${colors.primaryDark}!important;
    &:before {
      background-color: ${colors.primaryDark}!important;
    }
  }
`;
export const MessageToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);
`;

export const MessageContent = styled.div`
  padding: 15px;
  .max-length {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
    color: #adb5bd;
    .length {
      font-weight: 500;
    }
  }
`;
