export default {
  PAYMENT_SUCCESS: `<html>
<head>
    <style>
        @charset "UTF-8";
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: 0 solid transparent;
            font-size: 100%;
            font-weight: normal;
            font-style: normal;
            list-style: none
        }

        *::after,*::before {
            box-sizing: inherit
        }

        /* ヘッダーの背景色 */
        .fxC0 { background-color: {{headerColor}}  !important; }

        /* ヘッダーの文字色 */
        .fxC1 { color:{{headerTextColor}} !important; }

        /* ボタンの背景色 */
        .fxC3 { background-color: {{buttonColor}} !important; }
        .fxC6 { color: {{buttonTextColor}} !important; }


        .fxC2 { color: #666666 !important; }
        .fxC4 { color: #aaaaaa !important; }
        .fxC5 { color: #0000ee !important; }
        .LyMe { width: 100%; max-width: 300px; min-width: 0;}

        body,html {
            background-color: transparent
        }

        body {
            line-height: 1.4;
            color: #444;
            font-family: -apple-system,'BlinkMacSystemFont',Helvetica,Roboto,Sans-Serif;
            font-size: 16px;
            -webkit-user-select: none;
            -webkit-text-size-adjust: none
        }

        a {
            -webkit-touch-callout: none
        }

        a:active,a:focus,a:hover,a:link,a:visited {
            text-decoration: none
        }

        .LyMe .T1 {
            border-radius: 17px
        }

        .T1 {
            border-radius: 17px;
            overflow: hidden;
            background-color: #ffffff;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body,.T1 .t1Footer,.T1 .t1Hero {
            display: inherit;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body > div,.T1 .t1Footer > div,.T1 .t1Hero > div {
            flex-basis: auto
        }

        .T1 .t1Hero:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit
        }

        .T1 .t1Body > .MdBx {
            padding: 20px;
            padding-top: 19px
        }

        .T1 .t1Body.ExHasFooter > .MdBx {
            padding-bottom: 10px
        }

        .T1 .t1Footer > .MdBx {
            padding: 10px
        }

        .fxLTR,[dir="ltr"] {
            direction: ltr
        }

        .MdBtn,.MdBx,.MdTxt {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-flex: 1;
            flex: 1 0 0;
            min-width: 0;
            max-width: 100%
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBtn,.MdBx,.MdTxt {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        .MdBx {
            overflow: hidden;
            position: relative
        }

        .MdBx.vr > div {
            -webkit-box-flex: 0;
            flex: none;
            flex-basis: auto
        }

        .MdBx.hr {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%
        }

        .MdBx.hr > div {
            overflow: hidden;
            -webkit-box-flex: 1;
            flex: 1 0 0
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBx.hr > div {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBx.hr > div {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBx.hr > div {
                width:auto
            }
        }

        .T1 .MdBx.hr > div:first-child {
            margin-left: 0
        }

        .MdBx.itms-jfcC {
            -webkit-box-pack: center;
            justify-content: center
        }

        .MdBx.itms-algC {
            -webkit-box-align: center;
            align-items: center
        }

        .MdBx.vr.spcSm > div {
            margin-top: 4px
        }

        .MdBx.vr > div:first-child {
            margin-top: 0
        }

        .MdTxt {
            position: relative
        }

        .MdTxt p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap
        }

        .MdTxt.ExWrap p {
            text-overflow: clip;
            word-wrap: break-word;
            white-space: normal
        }

        .MdTxt.ExXs {
            font-size: 13px
        }

        .MdTxt.ExSm {
            font-size: 14px
        }

        .MdBtn {
            position: relative
        }

        .MdBtn a {
            display: inherit;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 52px;
            padding: 0 16px;
            border-radius: 8px;
            font-size: 16px
        }

        .MdBtn div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%
        }

        .MdBtn.ExBtnL a {
            color: #42659a
        }

        .ExMgnTLg {
            margin-top: 12px!important
        }

        .ExLLg {
            left: 12px
        }

        .ExWB {
            font-weight: bold!important
        }

        .ExWB * {
            font-weight: inherit
        }
        .flsm_wrap{
            background-color: #849ebf;
            padding:20px;
        }

</style>
</head>
<body>
<div class="flsm_wrap">
<div class="LyMe">
  <div class="T1 fxLTR" dir="ltr">
    <div class="t1Hero">
      <div class="itms-jfcC itms-algC MdBx vr fxC0" style="padding-top:20px; padding-bottom:20px;">
        <div class="fxC1 MdTxt ExWB">
          <p>決済完了のお知らせ</p>
        </div>
      </div>
    </div>
    <div class="ExHasFooter t1Body">
      <div class="spcSm MdBx vr">
        <div class="fxC2 MdTxt ExSm ExWrap">
          <p>ご注文頂いた決済が完了しましたので、ご連絡いたします。</p>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【ご利用決済手段】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【商品名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【決済金額】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●円(税込)</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【決済日時】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●年●●月●●日(曜日) ●●時●●分</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【ショップ名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t1Footer">
      <div class="spcSm MdBx vr">
        <div class="fxC3 MdBx vr" style="border-radius:7px;">
          <div class="ExBtnL MdBtn">
            <a>
              <div class="fxC6">詳細を確認する</div>
            </a>
          </div>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージは企業が保有する電話番号とLINEに登録された電話番号が一致した方に配信しております。</p>
            </div>
          </div>
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージにお心当たりがない方は下記よりご確認ください。 </p>
            </div>
            <div class="MdTxt fxC5 ExXs">
              <p>https://help.line.me/line/?contentId=20011417</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</body>
</html>`,
  PAYMENT_FAILED: `<html>
<head>
    <style>
        @charset "UTF-8";
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: 0 solid transparent;
            font-size: 100%;
            font-weight: normal;
            font-style: normal;
            list-style: none
        }

        *::after,*::before {
            box-sizing: inherit
        }

        /* ヘッダーの背景色 */
        .fxC0 { background-color: {{headerColor}}  !important; }

        /* ヘッダーの文字色 */
        .fxC1 { color:{{headerTextColor}} !important; }

        /* ボタンの背景色 */
        .fxC3 { background-color: {{buttonColor}} !important; }
        .fxC6 { color: {{buttonTextColor}} !important; }


        .fxC2 { color: #666666 !important; }
        .fxC4 { color: #aaaaaa !important; }
        .fxC5 { color: #0000ee !important; }
        .LyMe { width: 100%; max-width: 300px; min-width: 0;}

        body,html {
            background-color: transparent
        }

        body {
            line-height: 1.4;
            color: #444;
            font-family: -apple-system,'BlinkMacSystemFont',Helvetica,Roboto,Sans-Serif;
            font-size: 16px;
            -webkit-user-select: none;
            -webkit-text-size-adjust: none
        }

        a {
            -webkit-touch-callout: none
        }

        a:active,a:focus,a:hover,a:link,a:visited {
            text-decoration: none
        }

        .LyMe .T1 {
            border-radius: 17px
        }

        .T1 {
            border-radius: 17px;
            overflow: hidden;
            background-color: #ffffff;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body,.T1 .t1Footer,.T1 .t1Hero {
            display: inherit;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body > div,.T1 .t1Footer > div,.T1 .t1Hero > div {
            flex-basis: auto
        }

        .T1 .t1Hero:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit
        }

        .T1 .t1Body > .MdBx {
            padding: 20px;
            padding-top: 19px
        }

        .T1 .t1Body.ExHasFooter > .MdBx {
            padding-bottom: 10px
        }

        .T1 .t1Footer > .MdBx {
            padding: 10px
        }

        .fxLTR,[dir="ltr"] {
            direction: ltr
        }

        .MdBtn,.MdBx,.MdTxt {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-flex: 1;
            flex: 1 0 0;
            min-width: 0;
            max-width: 100%
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBtn,.MdBx,.MdTxt {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        .MdBx {
            overflow: hidden;
            position: relative
        }

        .MdBx.vr > div {
            -webkit-box-flex: 0;
            flex: none;
            flex-basis: auto
        }

        .MdBx.hr {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%
        }

        .MdBx.hr > div {
            overflow: hidden;
            -webkit-box-flex: 1;
            flex: 1 0 0
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBx.hr > div {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBx.hr > div {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBx.hr > div {
                width:auto
            }
        }

        .T1 .MdBx.hr > div:first-child {
            margin-left: 0
        }

        .MdBx.itms-jfcC {
            -webkit-box-pack: center;
            justify-content: center
        }

        .MdBx.itms-algC {
            -webkit-box-align: center;
            align-items: center
        }

        .MdBx.vr.spcSm > div {
            margin-top: 4px
        }

        .MdBx.vr > div:first-child {
            margin-top: 0
        }

        .MdTxt {
            position: relative
        }

        .MdTxt p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap
        }

        .MdTxt.ExWrap p {
            text-overflow: clip;
            word-wrap: break-word;
            white-space: normal
        }

        .MdTxt.ExXs {
            font-size: 13px
        }

        .MdTxt.ExSm {
            font-size: 14px
        }

        .MdBtn {
            position: relative
        }

        .MdBtn a {
            display: inherit;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 52px;
            padding: 0 16px;
            border-radius: 8px;
            font-size: 16px
        }

        .MdBtn div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%
        }

        .MdBtn.ExBtnL a {
            color: #42659a
        }

        .ExMgnTLg {
            margin-top: 12px!important
        }

        .ExLLg {
            left: 12px
        }

        .ExWB {
            font-weight: bold!important
        }

        .ExWB * {
            font-weight: inherit
        }
        .flsm_wrap{
            background-color: #849ebf;
            padding:20px;
        }

</style>
</head>
<body>
<div class="flsm_wrap">
<div class="LyMe">
  <div class="T1 fxLTR" dir="ltr">
    <div class="t1Hero">
      <div class="itms-jfcC itms-algC MdBx vr fxC0" style="padding-top:20px; padding-bottom:20px;">
        <div class="fxC1 MdTxt ExWB">
          <p>決済エラーのお知らせ</p>
        </div>
      </div>
    </div>
    <div class="ExHasFooter t1Body">
      <div class="spcSm MdBx vr">
        <div class="fxC2 MdTxt ExSm ExWrap">
          <p>お客様の決済が完了できなかったため、お知らせいたします。</p>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【ご利用決済手段】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【商品名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【決済金額】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●円(税込)</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【決済日時】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●年●●月●●日(曜日) ●●時●●分</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【ショップ名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t1Footer">
      <div class="spcSm MdBx vr">
        <div class="fxC3 MdBx vr" style="border-radius:7px;">
          <div class="ExBtnL MdBtn">
            <a>
              <div class="fxC6">詳細を確認する</div>
            </a>
          </div>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージは企業が保有する電話番号とLINEに登録された電話番号が一致した方に配信しております。</p>
            </div>
          </div>
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージにお心当たりがない方は下記よりご確認ください。 </p>
            </div>
            <div class="MdTxt fxC5 ExXs">
              <p>https://help.line.me/line/?contentId=20011417</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</body>
</html>
`,
  DELIVERY: `<html>
<head>
    <style>
        @charset "UTF-8";
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: 0 solid transparent;
            font-size: 100%;
            font-weight: normal;
            font-style: normal;
            list-style: none
        }

        *::after,*::before {
            box-sizing: inherit
        }
        /* ヘッダーの背景色 */
        .fxC0 { background-color: {{headerColor}} !important; }

        /* ヘッダーの文字色 */
        .fxC1 { color: {{headerTextColor}} !important; }

        /* ボタンの背景色 */
        .fxC3 { background-color: {{buttonColor}} !important; }
        .fxC6 { color: {{buttonTextColor}}!important; }


        .fxC2 { color: #666666 !important; }
        .fxC4 { color: #aaaaaa !important; }
        .fxC5 { color: #0000ee !important; }
        .LyMe { width: 100%; max-width: 300px; min-width: 0;}

        body,html {
            background-color: transparent
        }

        body {
            line-height: 1.4;
            color: #444;
            font-family: -apple-system,'BlinkMacSystemFont',Helvetica,Roboto,Sans-Serif;
            font-size: 16px;
            -webkit-user-select: none;
            -webkit-text-size-adjust: none
        }

        a {
            -webkit-touch-callout: none
        }

        a:active,a:focus,a:hover,a:link,a:visited {
            text-decoration: none
        }

        .LyMe .T1 {
            border-radius: 17px
        }

        .T1 {
            border-radius: 17px;
            overflow: hidden;
            background-color: #ffffff;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body,.T1 .t1Footer,.T1 .t1Hero {
            display: inherit;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body > div,.T1 .t1Footer > div,.T1 .t1Hero > div {
            flex-basis: auto
        }

        .T1 .t1Hero:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit
        }

        .T1 .t1Body > .MdBx {
            padding: 20px;
            padding-top: 19px
        }

        .T1 .t1Body.ExHasFooter > .MdBx {
            padding-bottom: 10px
        }

        .T1 .t1Footer > .MdBx {
            padding: 10px
        }

        .fxLTR,[dir="ltr"] {
            direction: ltr
        }

        .MdBtn,.MdBx,.MdTxt {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-flex: 1;
            flex: 1 0 0;
            min-width: 0;
            max-width: 100%
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBtn,.MdBx,.MdTxt {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        .MdBx {
            overflow: hidden;
            position: relative
        }

        .MdBx.vr > div {
            -webkit-box-flex: 0;
            flex: none;
            flex-basis: auto
        }

        .MdBx.hr {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%
        }

        .MdBx.hr > div {
            overflow: hidden;
            -webkit-box-flex: 1;
            flex: 1 0 0
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBx.hr > div {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBx.hr > div {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBx.hr > div {
                width:auto
            }
        }

        .T1 .MdBx.hr > div:first-child {
            margin-left: 0
        }

        .MdBx.itms-jfcC {
            -webkit-box-pack: center;
            justify-content: center
        }

        .MdBx.itms-algC {
            -webkit-box-align: center;
            align-items: center
        }

        .MdBx.vr.spcSm > div {
            margin-top: 4px
        }

        .MdBx.vr > div:first-child {
            margin-top: 0
        }

        .MdTxt {
            position: relative
        }

        .MdTxt p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap
        }

        .MdTxt.ExWrap p {
            text-overflow: clip;
            word-wrap: break-word;
            white-space: normal
        }

        .MdTxt.ExXs {
            font-size: 13px
        }

        .MdTxt.ExSm {
            font-size: 14px
        }

        .MdBtn {
            position: relative
        }

        .MdBtn a {
            display: inherit;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 52px;
            padding: 0 16px;
            border-radius: 8px;
            font-size: 16px
        }

        .MdBtn div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%
        }

        .MdBtn.ExBtnL a {
            color: #42659a
        }

        .ExMgnTLg {
            margin-top: 12px!important
        }

        .ExLLg {
            left: 12px
        }

        .ExWB {
            font-weight: bold!important
        }

        .ExWB * {
            font-weight: inherit
        }
        .flsm_wrap{
            background-color: #849ebf;
            padding:20px;
        }

</style>
</head>
<body>
<div class="flsm_wrap">
<div class="LyMe">
  <div class="T1 fxLTR" dir="ltr">
    <div class="t1Hero">
      <div class="itms-jfcC itms-algC MdBx vr fxC0" style="padding-top:20px; padding-bottom:20px;">
        <div class="fxC1 MdTxt ExWB">
          <p>発送完了のお知らせ</p>
        </div>
      </div>
    </div>
    <div class="ExHasFooter t1Body">
      <div class="spcSm MdBx vr">
        <div class="fxC2 MdTxt ExSm ExWrap">
          <p>発送完了をお知らせいたします。</p>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【発送日】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●年●●月●●日(曜日) ●●時●●分</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【商品名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
            <div class="spcSm MdBx vr">
              <div class="MdBx hr">
                <div class="MdTxt ExSm ExWrap ExWB">
                  <p>【ショップ名】</p>
                </div>
              </div>
              <div class="MdBx hr">
                <div class="ExLLg MdTxt ExWrap">
                  <p>●●●●●●</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t1Footer">
      <div class="spcSm MdBx vr">
        <div class="fxC3 MdBx vr" style="border-radius:7px;">
          <div class="ExBtnL MdBtn">
            <a>
              <div class="fxC6">詳細を確認する</div>
            </a>
          </div>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージは企業が保有する電話番号とLINEに登録された電話番号が一致した方に配信しております。</p>
            </div>
          </div>
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージにお心当たりがない方は下記よりご確認ください。 </p>
            </div>
            <div class="MdTxt fxC5 ExXs">
              <p>https://help.line.me/line/?contentId=20011417</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</body>
</html>
`,
  ORDER_CREATED: `<html>
<head>
    <style>
        @charset "UTF-8";
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: 0 solid transparent;
            font-size: 100%;
            font-weight: normal;
            font-style: normal;
            list-style: none
        }

        *::after,*::before {
            box-sizing: inherit
        }

        /* ヘッダーの背景色 */
        .fxC0 { background-color: {{headerColor}} !important; }

        /* ヘッダーの文字色 */
        .fxC1 { color: {{headerTextColor}} !important; }

        /* ボタンの背景色 */
        .fxC3 { background-color: {{buttonColor}} !important; }
        .fxC6 { color: {{buttonTextColor}}!important; }

        .fxC2 { color: #666666 !important; }
        .fxC4 { color: #aaaaaa !important; }
        .fxC5 { color: #0000ee !important; }
        .LyMe { width: 100%; max-width: 300px; min-width: 0;}

        body,html {
            background-color: transparent
        }

        body {
            line-height: 1.4;
            color: #444;
            font-family: -apple-system,'BlinkMacSystemFont',Helvetica,Roboto,Sans-Serif;
            font-size: 16px;
            -webkit-user-select: none;
            -webkit-text-size-adjust: none
        }

        a {
            -webkit-touch-callout: none
        }

        a:active,a:focus,a:hover,a:link,a:visited {
            text-decoration: none
        }

        .LyMe .T1 {
            border-radius: 17px
        }

        .T1 {
            border-radius: 17px;
            overflow: hidden;
            background-color: #ffffff;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body,.T1 .t1Footer,.T1 .t1Hero {
            display: inherit;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column
        }

        .T1 .t1Body > div,.T1 .t1Footer > div,.T1 .t1Hero > div {
            flex-basis: auto
        }

        .T1 .t1Hero:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit
        }

        .T1 .t1Body > .MdBx {
            padding: 20px;
            padding-top: 19px
        }

        .T1 .t1Body.ExHasFooter > .MdBx {
            padding-bottom: 10px
        }

        .T1 .t1Footer > .MdBx {
            padding: 10px
        }

        .fxLTR,[dir="ltr"] {
            direction: ltr
        }

        .MdBtn,.MdBx,.MdTxt {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-flex: 1;
            flex: 1 0 0;
            min-width: 0;
            max-width: 100%
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBtn,.MdBx,.MdTxt {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBtn,.MdBx,.MdTxt {
                width:auto
            }
        }

        .MdBx {
            overflow: hidden;
            position: relative
        }

        .MdBx.vr > div {
            -webkit-box-flex: 0;
            flex: none;
            flex-basis: auto
        }

        .MdBx.hr {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%
        }

        .MdBx.hr > div {
            overflow: hidden;
            -webkit-box-flex: 1;
            flex: 1 0 0
        }

        @media (-webkit-min-device-pixel-ratio: 1) {
            .MdBx.hr > div {
                width:0
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1) and (min-width: 0.001vmax) {
            .MdBx.hr > div {
                width:auto
            }
        }

        @media (min-resolution: 1dpi) {
            .MdBx.hr > div {
                width:auto
            }
        }

        .T1 .MdBx.hr > div:first-child {
            margin-left: 0
        }

        .MdBx.itms-jfcC {
            -webkit-box-pack: center;
            justify-content: center
        }

        .MdBx.itms-algC {
            -webkit-box-align: center;
            align-items: center
        }

        .MdBx.vr.spcSm > div {
            margin-top: 4px
        }

        .MdBx.vr > div:first-child {
            margin-top: 0
        }

        .MdTxt {
            position: relative
        }

        .MdTxt p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap
        }

        .MdTxt.ExWrap p {
            text-overflow: clip;
            word-wrap: break-word;
            white-space: normal
        }

        .MdTxt.ExXs {
            font-size: 13px
        }

        .MdTxt.ExSm {
            font-size: 14px
        }

        .MdBtn {
            position: relative
        }

        .MdBtn a {
            display: inherit;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 52px;
            padding: 0 16px;
            border-radius: 8px;
            font-size: 16px
        }

        .MdBtn div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%
        }

        .MdBtn.ExBtnL a {
            color: #42659a
        }

        .ExMgnTLg {
            margin-top: 12px!important
        }

        .ExLLg {
            left: 12px
        }

        .ExWB {
            font-weight: bold!important
        }

        .ExWB * {
            font-weight: inherit
        }
        .flsm_wrap{
            background-color: #849ebf;
            padding:20px;
        }

</style>
</head>
<body>
<div class="flsm_wrap">
<div class="LyMe">
  <div class="T1 fxLTR" dir="ltr">
    <div class="t1Hero">
      <div class="itms-jfcC itms-algC MdBx vr fxC0" style="padding-top:20px; padding-bottom:20px;">
        <div class="fxC1 MdTxt ExWB">
          <p>注文完了のお知らせ</p>
        </div>
      </div>
    </div>
    <div class="ExHasFooter t1Body">
      <div class="spcSm MdBx vr">
        <div class="fxC2 MdTxt ExSm ExWrap">
          <p>ご注文ありがとうございました。</p>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【商品名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【ご購入金額】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●円</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【注文日】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●年●●月●●日(曜日) ●●時●●分</p>
              </div>
            </div>
          </div>
          <div class="spcSm MdBx vr">
            <div class="MdBx hr">
              <div class="MdTxt ExSm ExWrap ExWB">
                <p>【ショップ名】</p>
              </div>
            </div>
            <div class="MdBx hr">
              <div class="ExLLg MdTxt ExWrap">
                <p>●●●●●●</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t1Footer">
      <div class="spcSm MdBx vr">
        <div class="fxC3 MdBx vr" style="border-radius:7px;">
          <div class="ExBtnL MdBtn">
            <a>
              <div class="fxC6">詳細を確認する</div>
            </a>
          </div>
        </div>
        <div class="MdBx vr ExMgnTLg">
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージは企業が保有する電話番号とLINEに登録された電話番号が一致した方に配信しております。</p>
            </div>
          </div>
          <div class="MdBx vr">
            <div class="MdTxt fxC4 ExWrap ExXs">
              <p>*このメッセージにお心当たりがない方は下記よりご確認ください。 </p>
            </div>
            <div class="MdTxt fxC5 ExXs">
              <p>https://help.line.me/line/?contentId=20011417</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</body>
</html>
`
};
