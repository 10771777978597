import React, { useState } from "react";
import { Form, FormItem, Select, DatePicker, Checkbox, } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Spin } from "antd";
import * as Yup from "yup";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import styled from "styled-components";

const settingTypes = ["COUPON_API_CHANGE", "START_NOTIF_MESSAGE", "MAINTENANCE_MODE"];
const { Option } = Select;

const FormSchema = (translate) => Yup.object({
  type: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
});

export default ({ onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = useState({
    type   : undefined,
    payload: false,
  });

  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Form {...formItemLayout}>
              <FormItem label={translate("admin.setting.form.type", "Type")} name="type" required>
                {<Select name="type" style={{ width: 180 }}placeholder= {translate("setting.type", "Type")} >
                  {settingTypes.map((item, index) => {
                    return <Option value={item} key={index}>{translate(item, item)}</Option>;
                  })}
                </Select>}
              </FormItem>
              <FormItem label={translate("admin.setting.form.payload", "Payload")} name="payload" required>
                <Checkbox name="payload" />
              </FormItem>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button onClick={handleSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  .send-date {
    display: inline-flex;
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width : 100%;
      }
    }
    .ant-form-item-control-input {
      width   : 381px;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .send-now {
    display: inline-block;
    padding: 10px 0;
    width: 100%;
    .ant-picker {
      width: 245px;
      margin-left: 10px;
    }
  }
`;