import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Checkbox, Modal, message, Tag, Row, Col, Input, Empty, Select } from "antd";
import { MyTable, RowAction } from "../../components";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { follower as followerApi, tags as tagsApi } from "../../apis";
import { Button } from "../../components/Design";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { CloseCircleOutlined, ExclamationCircleOutlined, SearchOutlined, TagOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Avatar from "antd/lib/avatar/avatar";
import TaggedForm from "./components/TaggedForm";
import TagForm from "../../components/Message/components/TagForm";
import moment from "moment";
import colors from "../../colors";
import NoImage from "../../assets/no_image51.png";
import AccountImage from "../../assets/account.png";
import { ManualLink } from "../../components/Manual";
import ManualDesc from "../../components/Manual/ManualDesc";

const { Option } = Select;
const { confirm } = Modal;

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const targetForm = React.useRef();
  const { checkRole } = usePermission();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [action, setAction] = React.useState();
  const [selected, setSelected] = React.useState({});
  const [followers, setFollowers] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = React.useState(false);
  const [count, setCount] = React.useState(0);
  useFetch(tagsApi.select(dispatch))();
  const { account, user, customer } = useSelector(state => state.general);
  // const [disabled ] = React.useEffect([checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "NO_REPORT", "STAFF"])])
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const [filters, setFilters] = React.useState({
    targets     : [],
    query       : "",
    isRakuten   : undefined,
    isTestTarget: undefined
  });

  const followerSync = async () => {
    setSyncing(true);
    try {
      let res = await followerApi.sync();
      setSyncing(false);
      message.success(translate("system.message.success", "Success"));
      myTableRef.current.reload();
    } catch (error) {
      if (error.message === "LINE_ACCOUNT_NOT_PREMIUM")
        message.error(translate("followers.get.alert", "Your Line offcial account must ne Verified or Premium account to use get followers information"));
      if (error.message === "FOLLOWER_SYNC_RUNNwING")
        message.error(translate("followers.get.alert.2", "Service is running in the background please wait until it stops"));

      setSyncing(false);
    }
  };

  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };
  const isRakutenFilter = [ {
    name : "Yes",
    value: true
  }, {
    name : "No",
    value: false
  }];
  const columns = useHeader({
    history,
    account,
    disabled,
    selected,
    tableTitles: {
      number          : translate("system.number", "No."),
      avatar          : translate("followers.avatar", "Avatar"),
      tag             : translate("followers.tag", "Tag"),
      registerDate    : translate("followers.createddate", "Register date"),
      richmenulinkDate: translate("followers.createddate.linkdate", "Richmenu link date"),
      action          : translate("system.action", "Action"),
      edit            : translate("system.edit", "Edit"),
      remove          : translate("system.delete", "Remove"),
      tagged          : translate("followers.connect.tag", "Connect tag"),
      untag           : translate("followers.remove.tag", "Remove tag"),
      rakuten         : translate("rakuten.follower.true", "Rakuten customer"),
      rakutenOrder    : translate("followers.rakuten.order", "Rakuten Order"),
      rakutenReview   : translate("followers.rakuten.review", "Rakuten Review"),
      richmenu        : translate("followers.richmenu", "Richmenu info"),
      setTest         : translate("followers.settest", "Set test"),
      removeTest      : translate("followers.removetest", "Remove test"),
      removeRakuten   : translate("followers.removerakuten", "Remove rakuten"),
      no              : translate("system.no", "No"),
      yes             : translate("system.yes", "Yes")
    },
    onSelect: (record, checked, type) => {
      if (type === "all") {
        if (checked === true) {
          setSelected({
            ...(checked === true ? myTableRef.current.getItems().reduce((accumulator, iterator) => ({ ...accumulator, [iterator._id]: true }), {}) : {}),
            all: checked
          });
        } else {
          setSelected({
            ...(checked === false ? myTableRef.current.getItems().reduce((accumulator, iterator) => ({ ...accumulator, [iterator._id]: false }), {}) : {}),
            all: checked
          });
        }

        setFollowers(myTableRef.current.getItems());
      } else {
        let selections = {
          ...selected,
          [record._id]: checked
        };
        setSelected(selections);
        setFollowers(myTableRef.current.getItems().filter(item => selections[item._id]));
      }
    },
    onClick: async (key, item) => {
      switch (key) {
        case "test": {
          try {
            await followerApi.setTest(item._id);
            message.success(translate("system.message.success", "Success"));
            myTableRef.current.reload();
          } catch (err) {
            console.log(err);
            if (err.message === "TEST_USER_LIMIT_EXCEEDED"){
              message.error(`${translate("follower.test.limit1", "Plan limit of")}${customer.plan.testUserLimit}${translate("follower.test.limit2", "test user exceeded")}`);
            } else {
              message.error(translate("system.error", "Follower is not found"));
            }
          }
          break;
        }
        case "removeRakuten": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("follower.rakutenremove.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await followerApi.rakutenRemove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }

        case "tag": {
          let selections = {
            [item._id]: true
          };
          setSelected(selections);
          setFollowers(myTableRef.current.getItems().filter(item => selections[item._id]));
          setAction(["tag"]);
          break;
        }
        case "reviews" : {
          history.push(`/products/rakuten/${item._id}/reviews`);
          break;
        }
        case "orders": {
          history.push({
            pathname: `/products/rakuten/${item._id}/orders`,
            state   : { id: item._id },
          });
          break;
        }
        case "edit": {
          history.push(`/product/${item._id}`);
          break;
        }
        default:
      }
    },
    onUntag: async ({ follower, tag, value }) => {
      await followerApi.untag({ followers: [follower._id], tags: [{ tag: tag, value }] });
      message.success(translate("system.message.success", "Success"));
      myTableRef.current.reload();
    },
    translate,
    name,
    checkRole
  });

  const onFilter = async () => {
    let target = await targetForm.current.validate();
    let targets = [];

    if (target?.tags?.length > 0)
      targets =[{
        ...target
      }];
    setFilters({ ...filters, targets: targets, query: query });
  };

  const onChangeIsRakuten = async (e) => {
    setFilters({ ...filters, isRakuten: e, query: query });
  };

  const onChangeisTestTarget = async (e) => {
    setFilters({ ...filters, isTestTarget: e, query: query });
  };
  const onClear = async () => {
    setFilters({ ...filters, targets: [], query: "", isRakuten: undefined, isTestTarget: undefined });
    setQuery("");
    targetForm.current.clear();
  };

  const onTarget = () => {
    setFilters({ ...filters, targets: [] });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (action && action[0] === "tag")
        await followerApi.tag(data);
      else
        await followerApi.untag(data);

      message.success(translate("system.message.success", "Success"));

      onCancel(true);
      data.setFormData({});

      setFollowers([]);
      setLoading(false);
    } catch (error) {
      if (error.message && error.message.followers)
        message.error(translate("system.choose.user", "No user has been selected"));
      if (error.message && error.message === "FOLLOWER_NOTFOUND") {
        message.error(translate("system.error", "Follower is not found"));
      }
      setLoading(false);
    }
  };

  const onCancel = (reload) => {
    if (reload === true) {
      setSelected({});
      myTableRef.current.reload();
    }
    setAction(null);
  };

  const onTag = () => {
    setAction(["tag"]);
  };

  const onUntag = () => {
    setAction(["untag"]);
  };

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.follower"></ManualLink>} title={translate("followers", "User List")} extra={[
        <Button key={0} disabled={followers.length === 0} onClick={onTag} type="primary"><TagOutlined /> {translate("followers.connect.tag", "Connect tag")}</Button>,
        <Button key={1} disabled={followers.length === 0} onClick={onUntag} type="danger"><TagOutlined /> {translate("followers.remove.tag", "Remove tag")}</Button>,
        ((()=>{
          if (user.role === "ADMIN" || user.role === "AGENCY"){
            return (<Button key={2} type="default" onClick={followerSync} icon={<VerticalAlignBottomOutlined />} loading={syncing} > {translate("followers.get", "Get followers")}</Button>);
          }
          return "";
        })())
      ]} />
      <ManualDesc translateKey ="manual.desc.follower" />
      <PageFilter>
        <UserCount>
          <div className="card-wrapper">
            <div className="card-icon" style={{ background: colors.primaryDark, color: "white" }}>
              <img className="card-image" style={{ width: "31px" }} src={AccountImage} />
            </div>
            <div className="card-body">
              <Tag color={colors.primaryDark}><strong style={{ fontSize: "16px" }}>{count}</strong><span style={{ marginLeft: "8px" }}>{translate("followers.total", "Followers")}</span></Tag></div>
          </div>
        </UserCount>
        <div style={{ width: "100%" }}>
          <TagForm notRequired={true} name="Search tags" ref={targetForm} onSubmit={onTarget} />
          <Row>
            <Col span={16} offset={8}>
              <SearchBox style={{ display: "flex", gap: "5px" }}>
                <Input value={query} onChange={e => setQuery(e.target.value)} placeholder={translate("followers.fulltext", "Full text search...")} prefix={<SearchOutlined />} />
                {<Select value={filters.isRakuten} style={{ width: 180 }} onChange={onChangeIsRakuten} placeholder= {translate("rakuten.follower.true", "Rakuten customer")} allowClear>
                  {isRakutenFilter.map((item, index) => {
                    return <Option value={item.value} key={index}>{translate(`system.${item.name.toLowerCase()}`, item.name)}</Option>;
                  })}
                </Select>}
                {<Select value={filters.isTestTarget} style={{ width: 180 }} onChange={onChangeisTestTarget} placeholder= {translate("follower.istesttarget", "Test user")} allowClear>
                  {isRakutenFilter.map((item, index) => {
                    return <Option value={item.value} key={index}>{translate(`system.${item.name.toLowerCase()}`, item.name)}</Option>;
                  })}
                </Select>}
                <Button type="primary" onClick={onFilter}><SearchOutlined />{translate("system.search.button", "Search")}</Button>
                <Button type="default" onClick={onClear}>{translate("system.clear", "Clear")}</Button>
              </SearchBox>
            </Col>
          </Row>
        </div>
      </PageFilter>
      <PageContent>
        <Modal
          maskClosable={false}
          title={action && action[0] === "tag" ? translate("followers.connect.tag", "Connect Tag") : translate("followers.remove.tag", "Remove Tag")}
          visible={action && action[0]}
          onCancel={onCancel}
          bodyStyle={{ padding: 0 }}
          footer={null}>
          {action && action[0] && <TaggedForm submitLoad={loading} onSubmit={onSubmit} onCancel={onCancel} selected={selected} followers={followers} />}
        </Modal>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={followerApi.list}
          onResult={(e) => setCount(e.count)}
        />
      </PageContent>
    </PageContainer>
  );
};
const useHeader = ({ selected = {}, onSelect, disabled, onClick, tableTitles, onUntag, translate, name, checkRole, account }) => {
  return [{
    title    : tableTitles.number,
    dataIndex: "key",
    width    : "20px",
  }, {
    width: 10,
    title: () => {
      return (
        <div>
          <Checkbox checked={selected.all} onChange={(e) => onSelect(null, e.target.checked, "all")} disabled={!disabled} />
        </div>
      );
    },
    render: (record) => {
      return (
        <div>
          <Checkbox checked={selected[record._id]} disabled={!disabled} onChange={(e) => onSelect(record, e.target.checked)} />
        </div>
      );
    }
  }, {
    title : tableTitles.avatar,
    render: (record) => {
      return (
        <div>
          <Avatar src={record.avatar} size={30} /> <span style={{ marginLeft: "15px", fontSize: "13px", fontWeight: "600" }}>{record.name}</span>
        </div>
      );
    }
  }, {
    title : tableTitles.tag,
    render: (record) => {
      return (
        <div>
          {record.tags.map((tag, index) => {
            switch (tag.type) {
              case "NUMBER": {
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag._id, value: tag.number })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="cyan">
                    {tag.name}:{tag.number}
                  </Tag>
                );
              }
              case "CHECKBOX": {
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag._id, value: tag.checked })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="orange">
                    {tag.name}:{tag.checked ? "YES" : "NO"}
                  </Tag>
                );
              }
              case "DATE": {
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag._id, value: tag.date })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="lime">
                    {tag.auto === undefined ? tag.name : translate("default.tag.registeredDate", tag.name)}:{moment(tag.date).format("YYYY-MM-DD")}
                  </Tag>
                );
              }
              default:
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag.parent || tag._id, value: tag._id })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="green">
                    {(() => {
                      if (tag.auto && tag.auto === "ages")
                        return `${tag.name}${name(tag)}`;
                      if (tag.auto && tag.auto === "subscriptionPeriods")
                        return `${tag.name}${name(tag)}`;
                      if (tag.auto)
                        return translate(`default.tag.${tag.value}`, tag.name);
                      return tag.name;
                    })()}
                  </Tag>
                );
            }
          })}
          {record.isTestTarget && <Tag color="orange">{translate("follower.istesttarget", "Test")}</Tag>}
        </div>
      );
    }
  }, {
    title : tableTitles.richmenu,
    render: (record) => {
      if (account && record.richmenuState && record?.richmenuState.richmenu && record.richmenuState.richmenu.lineRichMenuId && record.richmenuState.richmenu?.account === account._id){
        return (
          <>
            <ImageContainer>
              <img style={{ width: "92px" }} src={record?.richmenuState?.richmenu?.image.indexOf("http") === 0 ? record?.richmenuState.richmenu?.image : NoImage} />
            </ImageContainer>
            <span style={{ fontSize: "12px" }}>{record?.richmenuState.richmenu?.name}</span>
          </>
        );
      }
      return (
        <>
          {account && record.richmenuState.richmenu?.account === account?._id && <ImageContainer>
            <img style={{ width: "92px" }} src={record?.richmenuState?.richmenu?.image.indexOf("http") === 0 ? record?.richmenuState.richmenu?.image : NoImage} />
          </ImageContainer>}
          {record?.richmenuState?.richMenuId ? translate("richmenu.notlseg", "Set outside") : translate("richmenu.notset", "Not set") }
        </>
      );
    }
  }, {
    title : tableTitles.richmenulinkDate,
    render: (record) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tag>{record?.richmenuState?.richmenu?.richmenuSentDate && record.richmenuState.richmenu.account === account?._id ? moment(record?.richmenuState?.richmenu?.richmenuSentDate).format("YYYY-MM-DD HH:mm") : "-"}</Tag>
        </div>
      );
    }
  }, {
    title : tableTitles.registerDate,
    render: (record) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tag>{moment(record.createdAt).format("YYYY-MM-DD HH:mm")}</Tag>
        </div>
      );
    }
  }, {
    title : tableTitles.rakuten,
    render: (record) => {
      return (
        <div>
          {record.isRakuten === true ? tableTitles.yes : tableTitles.no}
        </div>
      );
    }
  }, disabled ? {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      let actions = {
        tag: tableTitles.tagged,
      };
      if (order.isRakuten){
        actions.orders = tableTitles.rakutenOrder;
        actions.reviews = tableTitles.rakutenReview;
        actions.removeRakuten = tableTitles.removeRakuten;
      }
      if (order.isTestTarget) {
        actions.test = tableTitles.removeTest;
      }
      if (!order.isTestTarget){
        actions.test = tableTitles.setTest;
      }
      return <RowAction actions={actions} onClick={(key) => onClick(key, order)} />;
    }
  }:{}];
};

const ImageContainer =styled.div`
  width: 92px;
  height: 63px;
  background-color: #FAFAFA;
  display : flex;
  align-items: center;
`;

const UserCount = styled.div`

.card-wrapper {
  border : solid 1px  rgb(247 247 249);
  width  : 300px;
  height : 80px;
  display: flex;
  border-radius : 5px;
}

.card-icon {
  border-right: solid 1px;
  width: 60px;
  background-color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size : 35px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.card-image {
  fill :red;
}
.card-body {
  display :flex;
  flex-direction : column;
  justify-content : center;
  margin-left : 10px;
}


`;
const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  .ant-input-affix-wrapper {
    display: flex;
  }
  button {
    margin-left: 10px;
  }
`;
const PageFilter = styled.div`
  display: flex;
  padding: 0 24px;
  align-items: center;
  .ant-form {
    width: 100%;
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
`;


