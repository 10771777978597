import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { notifMessageApi } from "../../apis";
import { Button, DatePicker, Descriptions, Empty, Input, Select, Space, Tag, } from "antd";
import MyTable from "../../components/MyTable";
import { useHistory } from "react-router-dom";
import { usePermission, useTranslate } from "../../hooks";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import colors from "../../colors";
import { SearchOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {
  const myTableRef = React.useRef(null);
  const { translate } = useTranslate();
  const [query, setQuery] = React.useState("");
  const history = useHistory();
  const { checkRole } = usePermission();
  const [isAdmin] = React.useState(checkRole(["AGENCY", "ADMIN"]) && checkRole(["AGENCY", "ADMIN"]));
  const [rangePicker, setRangePicker] = React.useState({
    startDate: moment().startOf("month"),
    endDate  : moment().endOf("month"),
  });
  const [filters, setFilters] = React.useState({
    query        : "",
    startDate    : moment().startOf("month").toDate(),
    endDate      : moment().endOf("month").toDate(),
    deliverStatus: undefined,
    type         : undefined
  });
  const [summary, setSummary] = React.useState({
    click      : {},
    cooperation: {},
    follow     : {},
    delivered  : {},
  });

  const onClear =()=> {
    setFilters({
      query    : "",
      startDate: moment().startOf("month").toDate(),
      endDate  : moment().endOf("month").toDate(),
    });
    setQuery("");
    setRangePicker({
      startDate: moment().startOf("month"),
      endDate  : moment().endOf("month"),
    });
  };
  const column = useHeader({
    history,
    translate,
    isAdmin,
    tableTitles: {
      no       : translate("system.number", "No."),
      sentdate : translate("delivery.report.sentdate", "Sent date"),
      senddate : translate("delivery.report.senddate", "Send date"),
      orderno  : translate("delivery.report.orderno", "Order No"),
      orderdate: translate("delivery.report.orderdate", "Order date"),
      phone    : translate("delivery.report.phone", "Phone"),
      type     : translate("delivery.report.type", "Type"),
      status   : translate("delivery.report.status", "Status"),
      follow   : translate("delivery.report.follow", "Follow"),
      linename : translate("delivery.report.linename", "Line name"),
    },
  });
  const changeRange = (values) => {
    setFilters({ ...filters, startDate: values && moment(new Date(values[0])).startOf("day").toDate(), endDate: values && moment(new Date(values[1])).endOf("day").toDate() });
    setRangePicker({ startDate: values && values[0], endDate: values && values[1] });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title={translate("delivery.report.header.title", "Delivery report")} />
      <PageContent>
        <PageFilter style={{ padding: 0, marginBottom: 20 }}>
          <Space>
            <Input
              name="query"
              value={query}
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
            <Select style={{ minWidth: 250 }} name="type" placeholder={translate("system.select", "Select")}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  type: e,
                });
              }}
              value={filters.type}
              allowClear
            >
              {["ORDER_CREATED", "PAYMENT_FAILED", "DELIVERY"].map((type, index) => {
                return <Option key={index} value={type}>{translate(`system.constant.deliverymessage.type.${type}`, type)}</Option>;
              })}
            </Select>
            <Select style={{ minWidth: 250 }}name="type" placeholder={translate("system.select", "Select")}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  deliverStatus: e,
                });
              }}
              value={filters.deliverStatus}
              allowClear
            >
              {["NEW", "FAILED", "SENT", "DELIVERED"].map((type, index) => {
                return <Option key={index} value={type}>{translate(`system.constant.deliverymessage.deliverstatus.${type}`, type)}</Option>;
              })}
            </Select>
          </Space>
          <Space>
            <RangePicker value={[rangePicker.startDate, rangePicker.endDate]} onChange={values => changeRange(values) } />
            <Button onClick={onClear}>
              {translate("system.clear", "Clear")}
            </Button>
          </Space>
        </PageFilter>
        <SummaryContainer style={{ marginBottom: "20px" }}>
          <Descriptions
            title="サマリー"
            bordered
            column={6}
          >
            <Descriptions.Item >-</Descriptions.Item>
            <Descriptions.Item >送信リクエスト数</Descriptions.Item>
            <Descriptions.Item >送信成功数</Descriptions.Item>
            <Descriptions.Item >クリック数</Descriptions.Item>
            <Descriptions.Item >友だち増加数</Descriptions.Item>
            <Descriptions.Item >連携完了数</Descriptions.Item>

            {/* Total Row */}
            <Descriptions.Item >合計</Descriptions.Item>
            <Descriptions.Item>{summary?.sent?.total || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.delivered?.total || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.click?.total || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.follow?.total || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.cooperation?.total || "-"}</Descriptions.Item>

            {/* Breakdown Rows */}
            <Descriptions.Item rowspan={3} className="no-bottom-border" label="内訳">購入完了通知</Descriptions.Item>
            <Descriptions.Item>{summary?.sent?.ORDER_CREATED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.delivered?.ORDER_CREATED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.click?.ORDER_CREATED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.follow?.ORDER_CREATED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.cooperation?.ORDER_CREATED || "-"}</Descriptions.Item>

            {/* <Descriptions.Item className="no-top-border" label=""> 決済完了通知</Descriptions.Item>
            <Descriptions.Item>{summary?.sent?.PAYMENT_SUCCESS || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.delivered?.PAYMENT_SUCCESS || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.click?.PAYMENT_SUCCESS || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.follow?.PAYMENT_SUCCESS || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.cooperation?.PAYMENT_SUCCESS || "-"}</Descriptions.Item> */}

            <Descriptions.Item className="no-top-border" label="" >発送完了通知</Descriptions.Item>
            <Descriptions.Item>{summary?.sent?.DELIVERY || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.delivered?.DELIVERY || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.click?.DELIVERY || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.follow?.DELIVERY || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.cooperation?.DELIVERY || "-"}</Descriptions.Item>

            <Descriptions.Item className="no-top-border" label="">決済エラー通知</Descriptions.Item>
            <Descriptions.Item>{summary?.sent?.PAYMENT_FAILED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.delivered?.PAYMENT_FAILED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.click?.PAYMENT_FAILED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.follow?.PAYMENT_FAILED || "-"}</Descriptions.Item>
            <Descriptions.Item>{summary?.cooperation?.PAYMENT_FAILED || "-"}</Descriptions.Item>
          </Descriptions>
        </SummaryContainer>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={column}
          onResult={(item) => setSummary(item.summary)}
          loadData={notifMessageApi.report}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ tableTitles, translate, }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.senddate,
    render: (item) => {
      return (
        <div>
          {item.isTest && <Tag color="green">{translate("delivery.message.report.testsend", "Test")}</Tag>}
          <div>{moment(item.sendDate).format("YYYY-MM-DD HH:mm")}</div>
        </div>
      );
    }
  }, {
    title : tableTitles.sentdate,
    render: (item) => {
      return (
        <div>
          {item.deliverStatus === "SENT" || item.deliverStatus === "DELIVERED" ? moment(item.sentDate).format("YYYY-MM-DD HH:mm") : "-"}
        </div>
      );
    }
  }, {
    title : tableTitles.orderno,
    render: (item) => {
      return (
        <div>
          {item.orderNo || "-"}
        </div>
      );
    }
  }, {
    title : tableTitles.orderdate,
    render: (item) => {
      return (
        <div>
          {moment(item.orderDate).format("YYYY-MM-DD HH:mm")}
        </div>
      );
    }
  }, {
    title : tableTitles.phone,
    render: (item) => {
      return (
        <div>
          {item.phone}
        </div>
      );
    }
  }, {
    title : tableTitles.type,
    render: (item) => {
      return (
        <div>
          {translate(`system.constant.deliverymessage.type.${item?.type}`, item?.type)}
        </div>
      );
    }
  }, {
    title : tableTitles.status,
    render: (item) => {
      return (
        <div>
          {translate(`system.constant.deliverymessage.deliverstatus.${item?.deliverStatus}`, item?.deliverStatus)}
        </div>
      );
    }
  }, {
    title : tableTitles.follow,
    render: (item) => {
      return <div>
        {item.isNewFollower ? "新規" : "既存"}
      </div>;
    }
  }, {
    title : tableTitles.linename,
    render: (item) => {
      return <div>
        {item?.lineUserName || "-"}
      </div>;
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-descriptions {
    width: 100%;
  }
  .no-bottom-border .ant-descriptions-item-label {
   border-bottom: none !important;
  }
  .no-top-border .ant-descriptions-item-label {
   border-bottom: none !important;
}
`;