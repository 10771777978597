import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { account, account as accountApi, auth, notifMessageApi } from "../../apis";
import { ManualDesc, ManualLink } from "../../components/Manual";
import { PlusOutlined, } from "@ant-design/icons";
import { message, Empty, Tag, Alert, Modal } from "antd";
import { Button } from "../../components/Design";
import MyTable from "../../components/MyTable";
import { useHistory } from "react-router-dom";
import { RowAction } from "../../components";
import { usePermission, useTranslate } from "../../hooks";
import React from "react";
import copy from "copy-to-clipboard";
import SendForm from "./components/SendForm";
import ShopNameForm from "./components/ShopNameForm";
import moment from "moment";

export default () => {
  const myTableRef = React.useRef(null);
  const { translate } = useTranslate();
  const history = useHistory();
  const [accountSetting, setAccountSetting] = React.useState(false);
  const { checkRole } = usePermission();
  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState([]);
  const [isAdmin] = React.useState(checkRole(["AGENCY", "ADMIN"]) && checkRole(["AGENCY", "ADMIN"]));
  const submitRef = React.useRef();

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data, { resetForm, setStatus }) => {
    try {
      await notifMessageApi.send(data);
    } catch (err) {
      message.error(translate("system.message.error", "Error"));
    }
    resetForm({});
    setStatus({ success: true });
    message.success(translate("system.message.success", "Success!"));
    myTableRef.current.reload();
    onCancel(true);
  };

  const getAccountSetting = async () => {
    try {
      const result = await accountApi.setting();
      setAccountSetting(result);
    } catch (error) {
      message.error("Error fetching account settings.");
    }
  };

  const onApply = async () => {
    setLoading(true);
    try {
      await notifMessageApi.apply();
      await getAccountSetting();
    } catch (error) {
      message.error("Error fetching account settings.");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAccountSetting();
  }, []);

  const onCopy = async (link) => {
    copy(link);

    notifMessageApi.summary({
      startDate: new Date(),
      endDate  : new Date()
    });
    message.success(translate("system.message.success", "Success"));
  };
  const column = useHeader({
    history,
    translate,
    onCopy,
    isAdmin,
    tableTitles: {
      no    : translate("system.number", "No."),
      type  : translate("delivery.message.type", "Type"),
      status: translate("delivery.message.status", "Status"),
      link  : translate("delivery.message.link", "Link"),
      time  : translate("delivery.message.time", "Time"),
      action: translate("system.action", "Action"),
      start : translate("delivery.message.start", "Start"),
      stop  : translate("delivery.message.stop", "Stop"),
      send  : translate("delivery.message.send", "Send"),
      edit  : translate("system.edit", "Edit"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/deliverymessage/${item.type}`);
          break;
        }
        case "send": {
          setAction(["send", item]);
          break;
        }
        case "start": {
          await notifMessageApi.status(item._id);
          await myTableRef.current.reload();
          break;
        }
        default:
      }
    },
  });

  if (accountSetting.notifMessageStatus !== "APPROVED"){
    return (
      <PageContainer>
        <PageHeader title={translate("delivery.message.header.title", "Delivery message")} />
        {/* <ManualDesc translateKey ="manual.desc.delivermessage" /> */}
        {accountSetting.notifMessageStatus === "UNAPPLIED" &&
        <PageContent>
          <Alert type="info" message={translate("delivery.message.apply.desc", "Apply for usage here!")}/>
          <Button style={{ marginTop: 10 }} loading={loading} type="primary" onClick={onApply}><PlusOutlined />{translate("delivery.message.apply.button", "Apply for use")}</Button>
        </PageContent>
        }
        {accountSetting.notifMessageStatus === "APPLIED" &&
        <PageContent>
          <Alert type="warning" message={translate("delivery.message.applied.desc", "Applied for use, await until admin responds to the request!")} />
        </PageContent>
        }
        {accountSetting.notifMessageStatus === "STOPPED" &&
        <PageContent>
          <Alert type="error" message={translate("delivery.message.stopped.desc", "Your usage of delivery message function has been revoked!")}/>
        </PageContent>
        }
      </PageContainer>
    );
  }

  return (
    <div>
      <PageContainer>
        <PageHeader title={translate("delivery.message.header.title", "Delivery message")} />
        <ShopNameForm ref={submitRef}></ShopNameForm>
        <PageContent>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            filters={{
              query: ""
            }}
            ref={myTableRef}
            rowKey={(record) => record._id}
            columns={column}
            loadData={notifMessageApi.list}
          />
        </PageContent>
        <Modal
          maskClosable ={false}
          title={translate("delivery.message.send", "Add")}
          visible={action[0] === "send"}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <SendForm onCancel={onCancel} onSubmit={onSubmit} action={action} />
        </Modal>
      </PageContainer>
    </div>
  );
};

const useHeader = ({ onAction, tableTitles, translate, onCopy, isAdmin }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.type,
    render: (item) => {
      return (
        <div>
          {translate(`system.constant.deliverymessage.type.${item.type}`, item.type)}
        </div>
      );
    }
  },
  // {
  //   title : tableTitles.link,
  //   render: (record) => {
  //     return <div>
  //       <Tag>{record.link}</Tag>
  //       <Button className="text-area-copy-button" size="small" type="default" onClick={() => {onCopy(record.link);}}>{translate("line.copy", "Copy")}</Button>
  //     </div>;
  //   }
  // },
  {
    title : tableTitles.time,
    render: (record) => {
      return <div>
        {record.isDelay ? <Tag color='green'>{moment(record.startTime).tz("Asia/Tokyo").format("HH:mm")}~{moment(record.endTime).tz("Asia/Tokyo").format("HH:mm")}</Tag>:<Tag>{translate("delivery.message.time.unlimited", "No time")}</Tag>}
      </div>;
    }
  },
  {
    title : tableTitles.status,
    render: (item) => {
      if (item.useStatus === "STARTED"){
        return <Tag color="green">{translate("deliverymessage.started", "Started")}</Tag>;
      }
      if (item.useStatus === "STOPPED"){
        return <Tag color="orange">{translate("deliverymessage.stopped", "Stopped")}</Tag>;
      }
      return "-";
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (item) => {
      if (isAdmin){
        return <RowAction actions={{
          edit : tableTitles.edit,
          start: item.useStatus === "STARTED" ? tableTitles.stop : tableTitles.start,
          send : tableTitles.send,
        }} onClick={(key) => onAction(key, item)} />;
      }
      return <RowAction actions={{
        edit: tableTitles.edit,
      }} onClick={(key) => onAction(key, item)} />;
    }
  }];
};
