import React from "react";
import { FormItem, Input, Select } from "formik-antd";
import { useTranslate } from "../../../hooks";
import ImageUpload from "../../../components/ImageUpload";
import { Content, FormfieldSection } from "./DeliveryMessageForm";
import ActionField from "./ActionField";

const { Option } = Select;

export default ({ values, setFieldValue, setFieldTouched })=> {
  const { translate } = useTranslate();
  const disabled = !values.recruitArea?.isRecruitArea;
  return (
    <Content>
      <div className="content">
        <FormfieldSection title={translate("recruitarea.title", "Title")}>
          <FormItem name="recruitArea.title" required>
            <Input disabled={disabled} name="recruitArea.title" placeholder={translate("recruitarea.title.placeholder", "Title")} />
          </FormItem>
        </FormfieldSection>
        <FormfieldSection title={translate("recruitarea.image", "Image")}>
          <FormItem name="recruitArea.image" required>
            <ImageUpload disabled={disabled} name="recruitArea.image" size="small" action="/api/general/upload" />
          </FormItem>
        </FormfieldSection>
        <FormfieldSection title={translate("recruitarea.text", "Text")}>
          <FormItem name="recruitArea.text" required>
            <Input disabled={disabled} name="recruitArea.text" placeholder={translate("recruitarea.text.placeholder", "Text")} />
          </FormItem>
        </FormfieldSection>

        <FormfieldSection title={translate("recruitarea.button", "Button label")}>
          <FormItem name="recruitArea.buttonText" required>
            <Input disabled={disabled} name="recruitArea.buttonText" placeholder={translate("recruitarea.buttonText.placeholder", "Button label")} />
          </FormItem>
        </FormfieldSection>
        {/*
        <FormfieldSection title={translate("recruitarea.url", "Link")} >
          <FormItem name="recruitArea.url" required>
            <Input disabled={disabled} name="recruitArea.url" placeholder={translate("recruitarea.url.placeholder", "Link")}/>
          </FormItem>
        </FormfieldSection> */}

        <FormfieldSection title={translate("recruitarea.action", "Action")} >
          <FormItem label={translate("recruitarea.actiontype", "Action type")} name="recruitArea.actionType">
            <Select onChange={() => {
              setFieldValue("recruitArea.isTagged", false);
              setFieldValue("recruitArea.tagType", 0);
              setFieldValue("recruitArea.couponCode", undefined);
              setFieldValue("recruitArea.taggedUrl", undefined);
              setFieldValue("recruitArea.url", undefined);
              setFieldValue("recruitArea.additionalParameter", undefined);
              setFieldValue("recruitArea.externalBrowser", undefined);
            }} disabled={disabled} style={{ marginBottom: "25px", width: "100%" }} name={"recruitArea.actionType"} placeholder={translate("system.select", "Select")} >
              <Option value="URL" >{translate("delivery.action.link", "Link")}</Option>;
              <Option value="COUPON" > {translate("delivery.action.coupon", "Coupon")}</Option>;
            </Select>
          </FormItem>
          <ActionField
            setFieldValue={setFieldValue}
            setFieldTouched={ setFieldTouched }
            disabled={disabled}
            area="recruitArea"
            actionType='recruitArea.actionType'
            values={values}
            linkUri='recruitArea.url'
            externalBrowser='recruitArea.externalBrowser'
            isTagged='recruitArea.isTagged'
            tagType='recruitArea.tagType'
            taggedUrl='recruitArea.taggedUrl'
            couponCode='recruitArea.couponCode'
            additionalParameter='recruitArea.additionalParameter'
          />
        </FormfieldSection>

      </div>
    </Content>
  );
};